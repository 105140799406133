// CollapsiblePanel.js
import React, {useState} from 'react';
import '../styles/CollapsiblePanel.css';
import {translate} from "./App";

const CollapsiblePanel = ({
                              name,
                              interval,
                              range,
                              isSelectedMark,
                              isDisplayedMark,
                              handleMarkClick,
                              handleMarkDoubleClick,
                              children
                          }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`${isSelectedMark && !isOpen ? "collapsible-panel-selected" : 'collapsible-panel'}`} onClick={(e) => {
            e.stopPropagation();
            handleMarkClick();
        }
        }
             onDoubleClick={(e) => {
                 e.stopPropagation();
                 setIsOpen(!isOpen);
                 handleMarkDoubleClick();
             }}>
            <div className={`${isSelectedMark && !isOpen ? "collapsible-header-selected" : isOpen ? "collapsible-header-displayed" : "collapsible-header"}`}
                 onClick={(e) => {
                     e.stopPropagation();
                     handleMarkClick();
                 }}
                 onDoubleClick={(e) => {
                     e.stopPropagation();
                     setIsOpen(!isOpen);
                     handleMarkDoubleClick();
                 }}>
                <span className='header-name'>{`${translate('Symbol')}: ${name}`} </span>
                <span className='header-name'>{`${translate('Interval')}: ${interval}`}</span>
                <span className='header-name'> {`${translate('Range')}: ${range}`} </span>
            </div>
            {isOpen && <div className="collapsible-content">{children}</div>}
        </div>);
};

export default CollapsiblePanel;
