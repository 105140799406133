import React, {useEffect, useState} from "react";
import '../styles/TableComponent.css';
import {isHebrew, translate} from "./App";


function TableComponent({columns, data, selectedRow, handleRequestClick}) {
    const [tableColumns, setTableColumns] = useState([]);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        setTableColumns(columns);
        setTableData(data);
    }, []);
    useEffect(() => {
        setTableColumns(columns);
    }, [columns]);
    useEffect(() => {
        setTableData(data);
    }, [data]);


    return (
        <div className={'table-container'}>
            <table>
                <thead>
                <tr>
                    {tableColumns.map(colName => (<th key={colName}>{translate(colName)}</th>))}
                </tr>
                </thead>
                <tbody>
                {tableData.map((rowData, index) => {
                    return (
                        <tr key={index}
                            className={(selectedRow === rowData) ? 'selected' : ''}
                            onClick={() => handleRequestClick(rowData)}
                        >
                            {(tableColumns).map((key, index) => {
                                return (<td key={index}>{rowData[key]}</td>)
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )

}

export default TableComponent;