import React, {memo, useState, useEffect, useMemo} from 'react';
import '../styles/LoginPage.css';  // Import the CSS for this component
import logo from '../resources/logo_images_2/logo800.png';
import StockAPI from '../components/StockApi';
import {useNavigate} from 'react-router-dom';
import configurations from "../resources/configurations.json";
import LanguageSelector from "./LanguageSelector";
import {translate} from "./App";
import {useDispatch, useSelector} from "react-redux";
import {addLog, loadData} from "../redux/dataBankReducer";

const LoginPage = ({handleLogCode}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token_length = 6;
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState('');
    const [textColor, setTextColor] = useState("green");
    // init the stockAPI object
    const stockApi = useMemo(() => new StockAPI(handleLogCode), [handleLogCode]);
    const [showAccessForm, setShowAccessForm] = useState(false);
    const [resetPasswordForm, setResetPasswordForm] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const [userName, setUserName] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newVerifiedPassword, setNewVerifyPassword] = useState('');
    const [token, setToken] = useState('');
    const [passwordMessage, setPasswordMessage] = useState('');
    const intervals = useSelector(state => state.dataBank.intervals);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        username: '',
        password: ''
    });

    const changeLocale = (newLocale) => {
        localStorage.setItem('appLocale', newLocale);
        window.location.reload(); // Reload the page to apply the new locale
    };


    const handleInputChange = (event) => {
        if (event.target.name === "password") {
            setPassword(event.target.value);
        } else if (event.target.name === "email") {
            setEmail(event.target.value);
        }
    };

    useEffect(() => {
        fetchData().then(_ => dispatch(addLog(['Data list on login loaded successfully.', 0]))).catch(err => dispatch(addLog(['Could not load data list in login', 2])));
    }, []);

    const fetchData = async () => {

        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            const options = {
                method: "POST",
                headers,
                body: null,
            };
            const response = await fetch(`${configurations.proxy_http_address}/data_list`, options);

            if (response.ok) {
                const data = await response.json();
                if (data) {
                    dispatch(loadData([data?.roles, data?.currencies, data?.initGraphs, data?.stocks]));
                } else {
                    dispatch(addLog([`Caught an error on fetchData. The error message is: ${response}`, 2]))
                }

            } else {
                dispatch(addLog([`Caught an error on fetchData. The error message is: ${response}`, 2]))

            }
        } catch (e) {
            dispatch(addLog([`Caught an error on fetchData. The error message is: ${e}`, 2]))
        }
    }

    useEffect(() => {
        const handleWindowClose = (e) => {
            // Add custom logic here if needed
            e.preventDefault();
            stockApi.closeProgram();
        };

        window.addEventListener('beforeunload', handleWindowClose);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('beforeunload', handleWindowClose);
        };
    }, [stockApi]);

    const handleLogin = async () => {
        if (intervals.length === 0) {
            setMessage(translate("Could not receive the data from the server. Please contact support"));
            setTextColor("orange");
            setTimeout(() => {
                setMessage("")
            }, 4000);
        }
        setDebugMessage(translate("Please wait..."));
        setTimeout(() => {
            setMessage("")
        }, 4000);
        const response = await stockApi.login(email, password);
        if (response["code"] === "200") {
            // Inform the parent component that login was successful.
            // If this is inside a component, you can use props.handleLogCode().
            navigate('/dashboard');
        } else {
            const message = response["msg"];
            if (response.status === 500) {
                setErrorMessage(translate("Could not login to server, please try again later..."));
            } else {
                setErrorMessage(translate("An error has occurred. Please make sure you are connected to the internet and try again later"));
            }
            dispatch(addLog([`Error: ${message}`, 2]));
        }
    };

    const handleRequestAccess = async () => {
        setShowAccessForm(true);
    };

    const handleForgotPassword = () => {
        setResetPasswordForm(true);
    };

    const handleBackToLogin = () => {
        //Hide the request access form.
        setShowAccessForm(false);
        setResetPasswordForm(false);
        setEmailSent(false);
        setUserAuthenticated(false)
        setUserName('');
        setNewPassword('');
        setNewVerifyPassword('');
        setToken('');
        setPasswordMessage('');

    };

    const handleFormInputChange = (event) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };

    const submitRequest = async (event) => {
        event.preventDefault();
        const firstName = event.target.firstName.value;
        const lastName = event.target.lastName.value;
        const email = event.target.email.value;
        const phoneNumber = event.target.phoneNumber.value;
        const username = event.target.username.value;
        const password = event.target.password.value;
        setDebugMessage(translate("Sending a request..."));
        //Check if all fields are filled.
        if (firstName === "" || lastName === "" || email === "" || phoneNumber === "" || username === "" || password === "") {
            setErrorMessage(translate("Please fill all fields"));
            return;
        }
        //Check if the email is valid.
        if (!email.includes("@")) {
            setErrorMessage(translate("Please enter a valid email"));
            return;
        }
        //Check if the phone number is valid.
        if (phoneNumber.length < 10) {
            setErrorMessage(translate("Please enter a valid phone number"));
            return;
        }
        //Send the request to the server.
        const response = await stockApi.request_access(email, firstName, lastName, phoneNumber, username, password);
        if (response["code"] === 200) {
            FitMessage(response.code);
            setShowAccessForm(false);

        } else {
            setErrorMessage(translate("An error occurred"));
            dispatch(addLog([`Status code ${response["code"]}: ${response["msg"]}`, 2]));
        }
    }

    const FitMessage = (code) => {
        if (code === 200) {
            setDebugMessage(translate('Action finished successfully'));
        } else {
            setErrorMessage(translate('Action failed. Please try again or contact support'))
        }
    }
    const renderLoginForm = () => {
        if (showAccessForm || resetPasswordForm) {
            return null;
        }
        return (
            <div className="login-form">
                <input className="input" type="text" name="email" placeholder={translate("Email")} value={email}
                       onChange={handleInputChange}/>
                <input className="input" type="password" name="password" placeholder={translate("Password")}
                       value={password}
                       onChange={handleInputChange}/>
                <div className="button-access-container">
                    <div className="forgot-password" onClick={handleForgotPassword}>{translate('Forgot password')}</div>
                    <div className="space"></div>
                    <button className="login-button" type="button" onClick={handleLogin}>{translate('Login')}</button>
                    <div className="space"></div>
                    <div className="space"></div>
                    <div className="space"></div>
                    <div className="request-access" onClick={handleRequestAccess}>{translate('Request Access')}</div>
                </div>
            </div>
        );
    };

    const setErrorMessage = (message) => {
        setTextColor("red");
        setMessage(message);
        setTimeout(() => {
            setMessage("");
            setTextColor("green");
        }, 3000);
    };

    const setDebugMessage = (message) => {
        setTextColor("green");
        setMessage(message);
        setTimeout(() => {
            setMessage("");
        }, 3000);
    };

    const sendToken = async () => {

        if (userName.length > 0) {

            //Send the request to the server.
            const response = await stockApi.verifyEmail(userName);
            if (response["code"] === 200) {
                setUserName('');
                setEmailSent(true);
                setNewPassword('');
                setNewVerifyPassword('');
                setShowAccessForm(false);
                setUserAuthenticated(false);


            } else {
                if (typeof response["msg"] !== "undefined" && response["msg"].length > 0) {
                    setErrorMessage(response["msg"]);
                    dispatch(addLog([`Status code ${response["code"]}: message : ${response["msg"]}`, 2]));
                } else {
                    setErrorMessage(translate("There was a problem with the server. Please try again later"));
                }
            }
        } else {
            setErrorMessage(translate('The username is illegal. Please try again'));
        }
    };

    const verifyToken = async () => {

        if (token.length === token_length) {

            //Send the request to the server.
            const response = await stockApi.verifyToken(userName, token);
            if (response["code"] === 200) {
                FitMessage(response.code)
                setShowAccessForm(false);
                setUserAuthenticated(true);
                setToken('');
            } else {
                FitMessage(response.code);
            }
        } else {
            setErrorMessage(translate('The token is illegal. Please try again.'));
        }
    };

    const handlePasswordChanged = (event) => {
        const value = event.target.value;
        setNewPassword(value);
        if (value === newVerifiedPassword) {
            setPasswordMessage('');
        } else {
            setPasswordMessage(translate('Passwords are not the same.'));
        }
    };

    const handleVerifyPasswordChanged = (event) => {
        const value = event.target.value;
        setNewVerifyPassword(value);
        if (value === newPassword) {
            setPasswordMessage('');
        } else {
            setPasswordMessage(translate('Passwords are not the same.'));
        }
    };

    const savePassword = async () => {

        //Send the request to the server.
        const response = await stockApi.savePassword(userName, newPassword);
        if (response["code"] === 200) {
            FitMessage(response.code);
            setShowAccessForm(false);
            setUserName('');
            setEmailSent(false);
            setNewPassword('');
            setNewVerifyPassword('');
            setToken('');
            setUserAuthenticated(false);

        } else {
            FitMessage(response.code);
            dispatch(addLog([`Status code ${response["code"]}: ${response["msg"]}`, 2]));
        }
    };

    const renderPasswordResetForm = () => {
        if (!resetPasswordForm || showAccessForm) {
            return null;
        }
        if (!emailSent) {
            return (
                <div className="insert-email">
                    <input className="input" type="text" name="username" placeholder={translate("Username")}
                           value={userName}
                           onChange={(event) => setUserName(event.target.value)}/>
                    <div className="button-access-container">
                        <button className="login-button" type="button"
                                onClick={sendToken}>{translate('Send token to email')}</button>
                        <div className="space"/>
                        <button className="login-button"
                                onClick={handleBackToLogin}>{translate('Back to Login')}</button>
                    </div>
                </div>
            );

        }
        if (emailSent && !userAuthenticated) {
            // Check for sent token.
            return (
                <div className="insert-email">
                    <input className="input" type="text" name="email_token" placeholder={translate("Verify code")}
                           value={token}
                           onChange={(event) => setToken(event.target.value)}/>
                    <div className="button-access-container">
                        <div className="forgot-password" onClick={handleForgotPassword}>Forgot password</div>
                        <button className="login-button" type="button" onClick={verifyToken}>Submit</button>
                        <div className="space"></div>
                        <button className="login-button" onClick={handleBackToLogin}>Back to Login</button>
                    </div>
                </div>
            );
        } else {
            // Display the new password insert and verification with save button.
            return (
                <div className="login-form">
                    <div className='password-container'>
                        <input className="input" type="password" name="password" placeholder={translate("Password")}
                               value={newPassword} onChange={handlePasswordChanged}/>
                    </div>
                    <div className='password-container'>
                        <input className="input" type="retype password" name="retype password"
                               placeholder={translate("Retype Password")} value={newVerifiedPassword}
                               onChange={handleVerifyPasswordChanged}/>
                    </div>
                    <label className='password-message'> {passwordMessage} </label>

                    <div className="button-access-container">
                        <button className="login-button" type="button"
                                onClick={savePassword}>{translate('Login')}</button>
                        <div className="space"></div>
                        <button className="login-button"
                                onClick={handleBackToLogin}>{translate('Back to Login')}</button>

                    </div>
                </div>
            );
        }
    };

    const renderAccessRequestForm = () => {
        if (!showAccessForm || resetPasswordForm) {
            return null;
        }
        return (
            <div className="access-request-form">
                {/* Your access request form elements go here */}
                <form onSubmit={submitRequest}>
                    {/* Add your form fields, labels, and submit button here */}
                    <input type="text" name="firstName" value={formData.firstName} onChange={handleFormInputChange}
                           placeholder={translate("First Name")}/>
                    <input type="text" name="lastName" value={formData.lastName} onChange={handleFormInputChange}
                           placeholder={translate("Last Name")}/>
                    <input type="email" name="email" value={formData.email} onChange={handleFormInputChange}
                           placeholder={translate("Email")}/>
                    <input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleFormInputChange}
                           placeholder={translate("Phone Number")}/>
                    <input type="text" name="username" value={formData.username} onChange={handleFormInputChange}
                           placeholder={translate("Username")}/>
                    <input type="password" name="password" value={formData.password} onChange={handleFormInputChange}
                           placeholder={translate("Password")}/>
                    <button className="login-button" type="submit">{translate('Submit Request')}</button>
                    <button className="login-button" onClick={handleBackToLogin}>{translate('Back to Login')}</button>

                </form>
            </div>
        );
    };


    return (


        <div className="login-container">
            <div className={'tops'}>
                <div className="top-left">
                    <button className="login-button"
                            onClick={() => window.location.href = "#/home"}>{translate('Home')}</button>
                </div>
                <div className="top-right">
                    <LanguageSelector onLocaleChange={changeLocale}/>
                </div>
            </div>

            <img className="login-logo" src={logo} alt="Company Logo"/>
            {showAccessForm ? renderAccessRequestForm() : resetPasswordForm ? renderPasswordResetForm() : renderLoginForm()}
            <div className="message-box" style={{color: textColor}}>{message}</div>
        </div>
    );


};

export default memo(LoginPage);
