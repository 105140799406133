import React, {memo} from 'react';
import ChartComponent from './ChartComponent';
import '../styles/ChartRow.css';

function ChartRow({indexes, chartVisibility, isZoomedIn, chartsInRow}) {

    return (
        <div className="chart-row" style={{gridTemplateColumns: `repeat(${chartsInRow}, 1fr)`}}>
            {indexes.map((index, idx) => (
                <React.Fragment key={index}>
                    <ChartComponent
                        index={index}
                        isZoomedIn={isZoomedIn}
                        isVisible={chartVisibility[idx]}
                    />
                </React.Fragment>
            ))}
        </div>
    );
}

export default memo(ChartRow);
