import {configureStore} from '@reduxjs/toolkit';
import dataBankReducer from '../redux/dataBankReducer';

const store = configureStore({
    reducer: {
        dataBank: dataBankReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Disables serializable state and action checks entirely
        }),
});

export default store;