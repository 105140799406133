import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import IntervalSelector from './IntervalSelector';
import RangeSelector from './RangeSelector';
import StockAPI, {convertToSeconds, isIntervalValidForRange} from './StockApi';
import '../styles/ChartComponent.css';
import {useRefresh} from './DashboardComponent';
import {useAdvancedChart} from './useTradingViewChart';
import configurations from "../resources/configurations.json";
import {useNavigate} from 'react-router-dom';
import {isHebrew} from "./App";
import {useDispatch, useSelector} from "react-redux";
import {
    addLog,
    setChartsDataToReset,
    setNewRange,
    setNewInterval,
    removeMarkFromReset, setChartsData
} from "../redux/dataBankReducer";

export function EntityId(id) {
    if (!(this instanceof EntityId)) {
        return new EntityId(id);
    }
    this.id = id;
}


function ChartComponent({index, isZoomedIn, isVisible}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const chartContainerRef = useRef();
    const chartWrapperRef = useRef();
    const {
        setErrorMessage,
        containerSizes,
        handleZoomIn,
        windowWidth,
        handleLogCode,
        backToLogin,
        symbolChangedFlag
    } = useRefresh();
    const chart = React.useMemo(() => React.createRef(), []);
    const currentData = useRef([]);
    const previousData = useRef([]);
    const address = configurations.chart_http_address;
    const stockApi = useMemo(() => new StockAPI(handleLogCode, backToLogin), [handleLogCode, backToLogin]);
    const [angleGroupId, setAngleGroupId] = useState(null);
    const [visibleRange, setVisibleRange] = useState({from: 0, to: 0});
    const intervals = useSelector(state => state.dataBank.intervals);
    const stockName = useSelector(state => state.dataBank.stockName); // Accessing stockName from Redux store
    const ranges = useSelector(state => state.dataBank.ranges);
    const markers = useSelector(state => state.dataBank.markers);
    const chartsDataToReset = useSelector(state => state.dataBank.chartMarksToReset);
    const chartsData = useSelector(state => state.dataBank.chartsData);
    const [chartWasInit, setChartWasInit] = useState(false);
    const [lastMarked, setLastMarked] = useState({starts: undefined, ends: undefined});


    /* charts and creation useEffect */
    useEffect(() => {
        if (!chart.current && chartContainerRef.current && isVisible) {
            chart.current = useAdvancedChart(stockName + '?' + index, intervals[index], ranges[index], chartContainerRef, isZoomedIn, address, false);
            // handle visible range change.
            chart.current?.onChartReady(() => {
                chart.current?.activeChart().onVisibleRangeChanged().subscribe(null, (range) => {
                    setVisibleRange(range);
                });
                setChartWasInit(true);
            });
        }
        const handleDoubleClick = (_) => {
            handleZoomIn(index);
        };

        if (!isZoomedIn) {
            chartWrapperRef?.current?.addEventListener('dblclick', handleDoubleClick);
        }

        return () => {
            if (!isZoomedIn) {
                chartWrapperRef?.current?.removeEventListener('dblclick', handleDoubleClick);
            }
        };
    }, []);

    useEffect(() => {
        const logout = () => {
            stockApi.closeProgram().then(_ => dispatch(addLog(['Closed program', 0])));
            localStorage.removeItem("user-role");
            localStorage.removeItem("user-name");
            localStorage.removeItem("user-token");
            localStorage.removeItem('isLoggedIn');
            // Back to login page.
            navigate('/login');
        };

        if (Object.keys(intervals).length === 0) {
            logout();
        }
    }, [intervals]); // Add dependencies here

    useEffect(() => {
        if (currentData.current.length > 0 && chartWasInit && Object.keys(markers).length > 0) {
            const {marker, okay} = extractMarker();
            if (okay && marker && currentData.current.length > 0) {
                const {
                    start_objects, end_objects, minPoints, maxPoints
                } = extractRectangleValues(currentData.current, marker, okay);
                const dataWithinAngleRange = findIntervalsWithAngleCriteria(marker, start_objects, end_objects, minPoints, maxPoints);
                if (dataWithinAngleRange !== null) {
                    dispatch(addLog([`Found ${start_objects.length} rectangles to mark and ${dataWithinAngleRange.length} angles.`, 0]));
                    if (index === 0) {
                        console.group(`Found ${start_objects.length} objects to mark and ${dataWithinAngleRange.length} angles`);
                        // print also the rectangles data .
                        start_objects.forEach((obj, i) => {
                            console.log(`rectangle ${i} : ${new Date(obj.t * 1000).toLocaleString()} - ${new Date(end_objects[i].t * 1000).toLocaleString()} Min: ${minPoints[i]} Max: ${maxPoints[i]}`);
                        })
                        dataWithinAngleRange.forEach((obj, i) => {
                            console.log(`Angle ${i} : ${new Date(obj.anglesStart.t * 1000).toLocaleString()} - ${new Date(obj.anglesEnd.t * 1000).toLocaleString()} Min: ${obj.min} Max: ${obj.max} Type: ${obj.type}`);
                        });
                        console.groupEnd();
                    }
                    drawRectangles(start_objects, end_objects, minPoints, maxPoints);
                    drawAngles(dataWithinAngleRange);
                    previousData.current.push(...currentData.current);
                    currentData.current = [];
                }
            }
        }
    }, [currentData.current, chartWasInit, markers]);

    useEffect(() => {
        if (index !== -1 && isVisible && chart.current !== undefined && chartsData[index] && Object.keys(chartsData[index])?.length > 0) {
            // Transform the new data
            const newData = transformData(chartsData[index]);

            // Function to merge, sort, and remove duplicates
            const mergeAndSortData = (current, newData) => {
                const map = new Map();

                // Add current data to the map
                current.forEach(item => map.set(item.t, item));

                // Add new data to the map, duplicates based on `t` will be ignored
                newData.forEach(item => {
                    if (!map.has(item.t) || item.t > current[current.length - 1]?.t) {
                        map.set(item.t, item);
                    }
                });

                // Convert the map back to an array and sort it by the timestamp
                return Array.from(map.values()).sort((a, b) => a.t - b.t);
            };

            // Merge, sort, and deduplicate data
            currentData.current = mergeAndSortData(currentData.current, newData);

            // Reset the chart data for the current index after updating
            dispatch(setChartsData([index, []]));
        }
    }, [chartsData[index]]); // Ensure all dependencies are listed


    useEffect(() => {
        try {
            const key = getMarkerKey();
            const isChanged = chartsDataToReset.includes(key);
            if (isChanged && previousData.current.length > 0) {
                dispatch(addLog([`Caught change in mark ${key}`, 0]));
                console.log(`Chart ${index} mark ${key} was changed. `);
                dispatch(addLog([`Chart ${index} mark ${key} was changed. `, 0]));
                const newData = [...previousData.current];
                currentData.current = [...newData, ...currentData.current];
                previousData.current = [];
                const newLis = chartsDataToReset.filter(obj => obj !== key);
                dispatch(setChartsDataToReset(newLis));
                setLastMarked({starts: undefined, ends: undefined});
                if (chart.current && chart.current.activeChart()) {
                    if (angleGroupId !== null) {
                        chart.current?.activeChart().shapesGroupController().removeGroup(angleGroupId);
                        setAngleGroupId(null);
                    }
                    chart.current.activeChart().removeAllShapes();
                    dispatch(addLog([`Reset marks on chart ${index}`, 0]));
                }
            }
        } catch (error) {
            dispatch(addLog([`UseEffect of stock name changed error. New val is : ${stockName}: error: ${error}`, 2]));
        }
    }, [chartsDataToReset]);

    useEffect(() => {
        if (isVisible) {
            if (!chart.current && chartContainerRef.current) {
                chart.current = useAdvancedChart(stockName + '?' + index, intervals[index], ranges[index], chartContainerRef, isZoomedIn, address);
                chart.current.onChartReady(() => {
                    chart.current?.activeChart().onVisibleRangeChanged().subscribe(null, (range) => {
                        setVisibleRange(range);
                    });
                    setChartWasInit(true);
                    // Remove all angles from the chart by their group that if angles exist,located in angleGroupId.

                    if (angleGroupId) {
                        chart.current?.activeChart().shapesGroupController().removeGroup(angleGroupId);
                        setAngleGroupId(null);
                    }
                    currentData.current = [...previousData.current];
                    previousData.current = [];

                });
            }
        }
    }, [isVisible]);

    useEffect(() => {
        try {
            if (chart.current !== undefined && chart.current !== null && chart.current.activeChart() && stockName) {
                chart.current.activeChart().setSymbol(stockName);
                chart.current.activeChart().removeAllShapes();
                setLastMarked({starts: undefined, ends: undefined});
                currentData.current = [];
                previousData.current = [];
                dispatch(addLog([`stockName changed on chart ${index} to ${stockName}`, 0]));
            }
        } catch (error) {
            dispatch(addLog([`UseEffect of stock name changed error. New val is : ${stockName}: error: ${error}`, 2]));
        }
    }, [symbolChangedFlag]);

    function getMarkerKey() {
        let sName = stockName;
        if (sName.includes('=X')) {
            //currency mode
            sName = sName.substring(0, sName.length - 2)
        }
        //stock mode
        return `${sName}-${intervals[index]}-${ranges[index]}`;
    }

    const drawAngles = (dataWithinAngleRange) => {
        const shapeIds = [];
        dataWithinAngleRange.forEach((obj, markIndex) => {
            dispatch(addLog([`obj angle : ${obj}`, 0]));
            const start = obj.anglesStart;
            const end = obj.anglesEnd;
            const min = obj.min;
            const max = obj.max;
            const angle = chart.current?.activeChart().createMultipointShape([{
                time: start.t, price: min  // Set the first point of the triangle
            }, {
                time: end.t, price: max  // Set the second point of the triangle
            }

            ], {
                shape: 'trend_angle', lock: false, overrides: {
                    color: '#389eee',  // Set the color of the triangle
                    linewidth: 1, linestyle: 0, // You can also set other styling properties if needed
                    rightEnd: 1, leftEnd: 1,
                }
            });
            shapeIds.push(angle);
        });
        if (shapeIds.length > 0) {
            if (angleGroupId !== null) {
                shapeIds.forEach(shapeId => {
                    chart.current?.activeChart()?.shapesGroupController().addShapeToGroup(angleGroupId, shapeId);
                });
            } else {
                chart.current?.chart().selection().add(...shapeIds);
                // Add the selection to the group
                setAngleGroupId(chart.current?.activeChart().shapesGroupController().createGroupFromSelection());
                chart.current?.chart().selection().clear();
            }
        }
    }

    function drawRectangles(start_objects, end_objects, minPoints, maxPoints) {
        for (let i in start_objects) {

            try {
                const start = start_objects[i];
                const end = end_objects[i];
                const min = minPoints[i];
                const max = maxPoints[i];
                chart.current?.activeChart().createMultipointShape([{time: start.t, price: min}, {
                    time: end.t, price: max
                }

                ], {
                    shape: 'rectangle', overrides: {
                        color: '#389eee', linewidth: 1, linestyle: 1,
                    }
                });

            } catch (e) {
                dispatch(addLog([`Error in chart ${index} in rectangle creation. Error is : ${e}`, 2]));
            }
        }
    }

    function extractRectangleValues(data, marker) {
        // Extract the new data to mark.
        let lis = filterData(data, marker);
        let start_objects = lis[0]
        let end_objects = lis[1];
        let minPoints = lis[2];
        let maxPoints = lis[3];
        //Save the first start_objects and end_objects to the lastMarked.
        if (start_objects.length > 0 && end_objects.length > 0) {
            setLastMarked(prev => {
                return {
                    starts: (prev.start === undefined || (start_objects[0] && start_objects[0].t < prev.start)) ? start_objects[0].t : prev.start,
                    ends: (prev.ends === undefined || (end_objects[end_objects.length - 1] && end_objects[end_objects.length - 1].t < prev.end)) ? end_objects[end_objects.length - 1].t : prev.ends
                };

            });
        }
        return {start_objects, end_objects, minPoints, maxPoints};
    }


    function extractMarker() {
        const markerKey = getMarkerKey();
        const marker = markers[markerKey];
        let okay = true;
        // Check if the marker exists
        if (!marker || (marker.normal_range_start === -1 && marker.normal_range_end === -1)) {
            okay = false;
        }
        return {marker, okay};
    }

    async function handleIntervalChange(event) {
        try {
            const newInterval = event.target.value;
            const newIntervalSeconds = convertToSeconds(newInterval, true);
            const rangeSeconds = convertToSeconds(ranges[index], false);
            if (chart.current !== undefined && ranges[index] === 'max' && newIntervalSeconds >= 86400 || isIntervalValidForRange(newIntervalSeconds, rangeSeconds)) {
                chart.current?.activeChart()?.setTimeFrame({
                    val: {type: 'period-back', value: ranges[index]}, res: newInterval,
                });
                chart.current?.activeChart()?.removeAllShapes();
                currentData.current = [];
                previousData.current = [];
                dispatch(addLog([`Interval changed on chart ${index} to ${newInterval}`, 0]));
                // update in the server.
                stockApi.updateUserPreferences('ChartInit', `${index}$interval`, newInterval)
                    .then(_ => {
                        dispatch(setNewInterval([index, newInterval]));
                        dispatch(addLog([`Updated chart ${index} interval preferences to ${newInterval}  successfully`, 0]));
                    }).catch(error => {
                    dispatch(addLog([`Failed to update chart ${index} interval preferences to ${newInterval}: ${error}`, 2]));
                });

            } else {
                dispatch(addLog(["The interval you selected is not valid for the current range Please select a closer one.", 0]));
                setErrorMessage("The interval you selected is not valid for the current range Please select a closer one.");
            }
        } catch (error) {
            dispatch(addLog([`An error occurred in handleIntervalChange : ${error}`, 2]));
        }
    }

    async function handleRangeChange(event) {
        try {
            const newRange = event.target.value;
            const intervalNumberOfSeconds = convertToSeconds(intervals[index], true); // Replace with your desired number of seconds
            const numberOfSeconds = convertToSeconds(newRange, false); // Replace with your desired number of seconds
            if (chart.current !== undefined && (newRange === 'max' && intervalNumberOfSeconds >= 86400) || isIntervalValidForRange(intervalNumberOfSeconds, numberOfSeconds)) {
                chart.current?.activeChart()?.setTimeFrame({
                    val: {type: 'period-back', value: newRange}, res: intervals[index],
                });
                chart.current?.activeChart()?.removeAllShapes();
                currentData.current = [];
                previousData.current = [];
                dispatch(addLog([`Range changed on chart ${index} to ${newRange}`, 0]));
                // update in the server.
                stockApi.updateUserPreferences('ChartInit', `${index}$range`, newRange)
                    .then(_ => {
                        dispatch(setNewRange([index, newRange]));
                        dispatch(addLog([`Updated chart ${index} range preferences to ${newRange}  successfully`, 0]));
                    }).catch(error => {
                    dispatch(addLog([`Failed to update chart ${index} range preferences to ${newRange}: ${error}`, 2]));
                });

            } else {
                dispatch(addLog([`The range you selected is not valid for the current interval`, 0]));
                setErrorMessage("The range you selected is not valid for the current interval.\nPlease select a closer one.")
            }
        } catch (error) {
            dispatch(addLog([`Got an error in handleRangeChange: ${error}`, 2]));
        }
    }

    function evaluateAngleCondition(action, given_angle, origin_angle) {
        switch (action) {
            case 'gt':
                return given_angle > origin_angle;
            case 'gte':
                return given_angle >= origin_angle;
            case 'eq':
                return given_angle === origin_angle;
            case 'lte':
                return given_angle <= origin_angle;
            case 'lt':
                return given_angle < origin_angle;
            default:
                dispatch(addLog([`Unknown action: ${action}`, 2]));
                return given_angle <= origin_angle;
        }
    }

    function findIntervalsWithAngleCriteria(marker, start_objects, end_objects, min, max) {
        const priceScale = chart.current?.activeChart().getPanes()[0].getRightPriceScales()[0];
        let priceRange = priceScale ? priceScale.getVisiblePriceRange() : {from: 0, to: 0};
        const timeRange = chart.current?.activeChart().getVisibleRange() || {from: 0, to: 0};
        const chartHeight = chart.current?.activeChart().getPanes()[0].getHeight();
        const chartWidth = chart.current?.activeChart().getTimeScale().width();
        let results = [];
        if (!chart.current || !priceRange) {
            return null;
        }
        let i = 0;
        while (i < start_objects.length) {
            const end = end_objects[i];
            const min_value = min[i];
            const max_value = max[i];
            //Check with next(if exists) if the angle between them is equal or less than marker.angle_in.
            if (i < start_objects.length - 1) {
                const nextStart = start_objects[i + 1];
                const nextMin = min[i + 1];
                const nextMax = max[i + 1];
                const isCurrentPointLower = nextMax > max_value;
                if (isCurrentPointLower) {
                    const timeDifference = nextStart.t - end?.t;
                    //Calculate the angle.
                    const relativeTimeDiff = timeDifference / (timeRange.to - timeRange.from);
                    const width = relativeTimeDiff * chartWidth;
                    //Calculate the price diff from low to high.
                    const priceDiff = nextMax - min_value;
                    const relativePriceDiff = priceDiff / (priceRange.to - priceRange.from);
                    const height = relativePriceDiff * chartHeight;
                    //given the y and x values calculate the angle.
                    let angle = (Math.atan(height / width) * 180 / Math.PI);
                    //if isCurrentPointLower is true, then the angle is 90 - angle_in.
                    if (evaluateAngleCondition(marker.action_out, 90 - angle, marker.angle_out)) {
                        //Add to results.
                        results.push({
                            anglesStart: end,
                            anglesEnd: nextStart,
                            min: isCurrentPointLower ? min_value : max_value,
                            max: isCurrentPointLower ? nextMax : nextMin,
                            type: isCurrentPointLower ? 'lower' : 'higher'
                        });
                    }
                }
            }
            i++;

        }
        console.groupEnd();
        return results;
    }


    const getWidth = () => {
        try {
            if (isZoomedIn) {
                return windowWidth * 0.6;
            } else {
                return containerSizes.width;
            }
        } catch (e) {
            dispatch(addLog([`getWidth exception. Container width value is : ${containerSizes.width} .Error is : ${e}`, 2]));
            return 200;
        }
    }

    const getHeight = () => {
        try {
            if (isZoomedIn) {
                return windowWidth * 0.4;
            } else {
                return containerSizes.height;
            }
        } catch (e) {
            dispatch(addLog([`getHeight exception. Container height value is : ${containerSizes.height} .Error is : ${e}`, 2]));
            return 200;
        }
    }

    const transformData = (data) => {
        // Get the keys of the object (assuming all arrays are of the same length)
        const keys = Object.keys(data);

        // Check if the input object is empty
        if (keys.length === 0) {
            return [];
        }

        // Assuming all arrays are of the same length
        const length = data[keys[0]].length;

        // Create an array of objects
        const result = [];

        for (let i = 0; i < length; i++) {
            const newObj = {};
            keys.forEach(key => {
                newObj[key] = data[key][i];
            });
            result.push(newObj);
        }
        return result;
    };

    const filterData = (processed_data, marker) => {
        try {
            debugger
            let start_objects = [];
            let end_objects = [];
            let min = [];
            let max = [];
            let min_value = 0;
            let max_value = 0;
            let i = 0;
            let j = 0;
            // First filter the data to check only if the t value is in the range of the visible range.
            // Also filter the data that was already processed, using the time range of the already processed located in the lastMarked state as starts and ends timestamps.
            processed_data = processed_data.filter((obj) => {
                return ((lastMarked.starts === undefined || obj.t < lastMarked.starts) && (lastMarked.ends === undefined || obj.t > lastMarked.ends));
            });
            while (i < (processed_data.length - marker.min_interval_width)) {
                const window = [];
                for (j = i; j < i + marker.max_interval_width; j++) {
                    if (j >= processed_data.length) {
                        break;
                    }
                    window.push(processed_data[j]);
                }

                while (window.length >= marker.min_interval_width) {
                    const min_max = findMinMax(window);
                    min_value = min_max[0];
                    max_value = min_max[1];
                    const range = max_value - min_value;
                    //  extract first value after (if exists and first value before (if exists) the window that is not in the range.
                    let firstValueBefore = -1;
                    let firstValueAfter = -1;
                    if (i > 0) {
                        firstValueBefore = processed_data[i - 1];
                    }
                    if (window[-1] < processed_data.length) {
                        firstValueAfter = processed_data[window[-1] + 1];
                    }
                    // check if the range is in the normal range. and if there is first value before or after the window, check if their h-l value is 3 times larger than the window's.
                    const cond = (range >= marker.normal_range_start && range <= marker.normal_range_end) && ((firstValueBefore === -1 || (firstValueBefore.h - firstValueBefore.l) >= 3 * range) || (firstValueAfter === -1 || (firstValueAfter.h - firstValueAfter.l) >= 3 * range));
                    // Make sure the new inserted values are not intersected with the last inserted values.
                    const lastEnd = end_objects[end_objects.length - 1];
                    if (cond) {
                        const cond2 = (lastEnd === undefined || window[0].t > lastEnd.t);
                        if (cond2) {
                            start_objects.push(window[0]);
                            end_objects.push(window[window.length - 1]);
                            min.push(min_value);
                            max.push(max_value);
                            break;
                        }
                    }
                    window.shift();
                }
                i = j;

            }
            return [start_objects, end_objects, min, max];
        } catch (e) {
            return [[], [], [], []];
        }
    }

    const findMinMax = (window) => {
        let min = window[0].l;
        let max = window[0].h;
        for (let i = 1; i < window.length; i++) {
            if (window[i].l < min) {
                min = window[i].l;
            }
            if (window[i].h > max) {
                max = window[i].h;
            }
        }
        return [min, max];
    }

    return (<div className="chart-wrapper" ref={chartWrapperRef} style={{
        width: `${getWidth()}px`, height: `${getHeight()}px`, display: isVisible ? 'block' : 'none'
    }}>

        <div className="chart-container" ref={chartContainerRef} style={{
            width: `${containerSizes.width}px`,
            height: `${containerSizes.height - 40}px`,
            display: isVisible ? 'flex' : 'none'
        }}>
        </div>
        <div className={'controls-container'}>
            <div className={'controls-row'} style={{direction: isHebrew() ? 'rtl' : 'ltr'}}>
                <IntervalSelector index={index} handleIntervalChange={handleIntervalChange}/>
                <RangeSelector index={index} handleRangeChange={handleRangeChange}/>
            </div>
        </div>
    </div>);
}


export default memo(ChartComponent);