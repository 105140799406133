import {useCallback} from 'react';
import {createChart} from 'lightweight-charts';
import {widget} from '../charting_library';
import configurations from '../resources/configurations.json';

function getLanguageFromURL() {
    const regex = new RegExp('[\\?&]lang=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const isChartInitialized = (container) => {

    if (container) {
        return container.querySelector('.tv-lightweight-charts') !== null;
    }

    return false;
};

// Lighweight charts.
export const useLightweightChart = (chartContainerRef, handleCrosshairMove, candleSticks) => {
    return useCallback(() => {
        try {
            if (isChartInitialized(chartContainerRef.current)) {
                return;
            }
            const newChart = createChart(chartContainerRef.current, {
                autoSize: true,
                layout: {
                    backgroundColor: '#ffffff',
                    textColor: 'rgba(0, 0, 0, 0.9)',
                },
                grid: {
                    vertLines: {
                        color: 'rgba(197, 203, 206, 0.5)',
                    },
                    horzLines: {
                        color: 'rgba(197, 203, 206, 0.5)',
                    },
                },
                rightPriceScale: {
                    borderColor: 'rgba(197, 203, 206, 0.8)',
                },
            });
            newChart.timeScale().fitContent();
            candleSticks.current = newChart.addCandlestickSeries({
                upColor: 'green',
                downColor: 'red',
                wickUpColor: 'black',
                wickDownColor: 'black',
            });

            // Get the current users primary locale

            //const currencySymbol = '$'; // Currency symbol for USD
            const myPriceFormatter = (price) => {

                // format for display the dollar symbol with the values.
                return `${price.toFixed(5)}`;
            };

            const myTimeFormatter = (timestamp) => {
                const date = new Date(timestamp * 1000);
                return date.toLocaleString('en-GB', {
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                });
            };

            if (newChart) {
                // Apply the custom priceFormatter to the chart
                newChart.applyOptions({
                    localization: {
                        priceFormatter: myPriceFormatter,
                        timeFormatter: myTimeFormatter,
                    },
                });
            }


            newChart.subscribeCrosshairMove(param => {
                if (
                    !chartContainerRef.current ||
                    param.point === undefined ||
                    !param.time ||
                    param.point.x < 0 ||
                    param.point.x > chartContainerRef.current.clientWidth ||
                    param.point.y < 0 ||
                    param.point.y > chartContainerRef.current.clientHeight
                ) {
                    handleCrosshairMove(null, null, null, null, 0, 0);
                } else {
                    try {

                        const data = param.seriesData.get(candleSticks.current) || {};
                        const open = data.open !== undefined ? data.open : data.close;
                        const close = data.close !== undefined ? data.close : data.close;
                        const low = data.low !== undefined ? data.low : data.close;
                        const high = data.high !== undefined ? data.high : data.close;
                        const offset = chartContainerRef.current.getBoundingClientRect();
                        handleCrosshairMove({
                            "open": open,
                            "close": close,
                            "low": low,
                            "high": high
                        }, param.point.x + offset.x, param.point.y + offset.y - 150);
                    } catch (error) {
                    }
                }
            });
            return newChart;
        } catch (error) {
        }

    }, []);
};


// Advanced charts charts.
export const useAdvancedChart = (stock, interval, range, chartContainerRef, isZoomedIn, server_url, on_Home) => {

        try {
            if (isChartInitialized(chartContainerRef.current)) {
                return;
            }
            const defaultProps = {
                symbol: 'AAPL', // Stock/currency name.
                interval: 'W', // The interval size.
                timeframe: '12M', // The time range to display.
                timezone: "Israel/Jerusalem",
                datafeedUrl: 'https://demo_feed.tradingview.com', // Url to the datafeed implementation for the widget.
                libraryPath: './charting_library/', // Url to the chart library files for contruction of the chart.
                chartsStorageUrl: 'https://saveload.tradingview.com', // Url for the storage location of the charts.
                chartsStorageApiVersion: '1.1', // A version of your backend. Supported values are: '1.0' | '1.1'.
                clientId: 'tradingview.com',
                userId: 'public_user_id',
                fullscreen: false, // Set if the chart should cover the whole screen or only in the given size.
                autosize: true, // If the chart should use all the available space in the container and resize when the container itself is resized.
                studiesOverrides: {},
            };

            // Another constructor settings are at the bottom of this file.
            const widgetOptions = {
                symbol: on_Home ? defaultProps.symbol : stock,
                symbol_search: 1500,
                overrides: {
                    "mainSeriesProperties.minTick": "10000,1,false"

                },
                // BEWARE: no trailing slash is expected in feed URL
                datafeed: new window.Datafeeds.UDFCompatibleDatafeed(on_Home ? defaultProps.datafeedUrl : server_url, configurations.datafeed_update_rate), // the datafeed instance for updating the chart.
                interval: on_Home ? defaultProps.interval : interval, // The interval size.
                timeframe: on_Home ? defaultProps.timeframe : range, //The time range to display. If not specified, set to cover the screen.
                timezone: defaultProps.timezone, // Set the timezone for the dates displayed on the chart.
                debug: false, // Weather to display logs on the console or not.
                container: chartContainerRef?.current,  // The container in the DOM holding the chart.
                library_path: defaultProps.libraryPath,// Url to the chart library files for contruction of the chart.
                locale: getLanguageFromURL() || 'en', // Language of the chart and buttons.
                disabled_features: !isZoomedIn ? ["header_quick_search", "chart_zoom", "main_series_scale_menu", "header_symbol_search", "header_compare", "header_indicators", "header_resolutions", "left_toolbar", "timeframes_toolbar", "header_widget_dom_node", "symbol_search_hot_key", "header_chart_type", "header_settings", "header_undo_redo", "header_interval_dialog_button", "header_saveload", "control_bar", "use_localstorage_for_settings"] :
                    ["header_quick_search", "main_series_scale_menu", "header_symbol_search", "header_compare", "header_indicators", "header_resolutions", "header_fullscreen_button"], //Array containing names of features that should be disabled by default.
                price_precision: 6,
                //Removes toolbars from chart
                //enabled_features: ['study_templates'], // Array containing names of features that should be enabled by default.
                charts_storage_url: defaultProps.chartsStorageUrl, // Url for the storage location of the charts.
                charts_storage_api_version: defaultProps.chartsStorageApiVersion, // A version of your backend. Supported values are: '1.0' | '1.1'.
                client_id: defaultProps.clientId, //  Client ID for the high-level saving/loading charts API.
                user_id: defaultProps.userId, // User ID for the high-level saving/loading charts API.
                fullscreen: defaultProps.fullscreen, // Whether the chart should use all the available space in the window.
                autosize: defaultProps.autosize, // If the chart should use all the available space in the container and resize when the container itself is resized.
                studies_overrides: defaultProps.studiesOverrides, // Use this option to customize the style or inputs of the indicators.An array.

            };

            const tvWidget = new widget(widgetOptions);
            console.log(TradingView.version());
            return tvWidget;
        } catch (e) {
            console.error('Error during the widget init. Error: ', e);
            return null;
        }
    }
;


// The widget constructor additional options : 

// const widgetOptions = {
// symbol: 'AAPL', // The stock displayed symbol.
// // BEWARE: no trailing slash is expected in feed URL

// datafeed: new window.Datafeeds.UDFCompatibleDatafeed('https://demo_feed.tradingview.com'), // the datafeed instance for updating the chart.

// interval: '1D', // The interval size.

// timeframe: '3Y', //The time range to display. If not specified, set to cover the screen.

// timeframes: [{text:'1Y, resolution: 'W', description: '1 year},{}] // Setting default resolution to ranges. will be displayed in the chart buttom.
// TODO: read the resolution documentation.

// timezone: "Israel/Jerusalem",,// Set the timezone for the dates displayed on the chart.

// debug: false , // Wheater to display logs on the console or not.

// width: 300px, // the width in case fullscreen not true.

// height: 300px, // the height in case fullscreen not true.

//symbol_search: 1000, //The time delay in ms to wait before sending the symbol search request.

// auto_save_delay: 5, // The delay time between changing chart and markers to the saving of the changes.

// toolbar_bg: '#f4f7f9, // the background color of the toolbars on the chart.

//studt_count_limit: 5, // Limiting the number of compares symbols.

//studies_access: {  // For setting blacklist or whitelist of the indicators.
// type: "black | "white" ,  // Display only the white, or what not black.
//tools: [{name :"<study tool name>", grayed: true|false},...] // If not grayed the tools not allowed will not be displayed at all.
// }

//drawing_access: {  // For setting blacklist or whitelist of the drawing tools.
// type: "black | "white" ,  // Display only the white, or what not black.
//tools: [{name :"<study tool name>", grayed: true|false},...] // If not grayed the tools not allowed will not be displayed at all.
// }

//saved_data: ,{<the saved object>} // load pre-saved chart during initialization.

//numeric_formatting: {"decimal_sign: ","}, // Globalization settings. Can set the decimal sign to comma or dot(default).

// container: chartContainerRef.current,  // The container in the DOM holding the chart.

// library_path:  '/charting_library/',// Url to the chart library files for contruction of the chart.

// locale: getLanguageFromURL() || 'en', // Language of the chart and buttons.

// disabled_features: ['use_localstorage_for_settings'], //Array containing names of features that should be disabled by default.
// !!! Here is the toolbars can be removed. !!!

// enabled_features: ['study_templates'], // Array containing names of features that should be enabled by default.

// charts_storage_url: defaultProps.chartsStorageUrl, // Url for the storage location of the charts.

// charts_storage_api_version: defaultProps.chartsStorageApiVersion, // A version of your backend. Supported values are: '1.0' | '1.1'.

// client_id: defaultProps.clientId, //  Client ID for the high-level saving/loading charts API.

// user_id: defaultProps.userId, // User ID for the high-level saving/loading charts API.

// fullscreen: defaultProps.fullscreen, // Whether the chart should use all the available space in the window.

// autosize: defaultProps.autosize, // If the chart should use all the available space in the container and resize when the container itself is resized.

// studies_overrides: defaultProps.studiesOverrides, // Use this option to customize the style or inputs of the indicators.An array.

// overrides: { "mainSeriesProperties.style":2 } // Allow to override properties of the library without having to go throught the chart settings like line instead of candles. The settings are in a seperte document.
// widget.applyoverride(...) will allow you to programatically add overrides on the fly .

// custom_indicator_getter: function(pineJS){ return Promise.resolve({<The indicator object created from the template.>})} // Allows to add new indicators.

// studies_override : { "volume(indicator name).valume(property to change name).color(what to change in it).0([falling,growing] color)": "#00ffff"  // changing the color of the negative movement from red to blue.} // Allows to change default settings like indicators and built-in features.

// theme: "Light", // Starting from version 1.13 , add custom theme color to the chart. Values are "Light| "Dark".

// custom_css_url: css/style/css , //Starting from version 1.14, Add custom css to chart. url to the css file should be absolute or relative to static folder.

// custom_font_family: "Chalkduster" , //setting different font family to the chart price and time axis and to added text in the chart. The toolbars will stay the same.

// favorites: { intervals: ["1D", "3D", "3W", "W", "M"], CHARTtYPES: ["Area", "Lines"]} //allow users to save preferances in local storage such as resolutions, chart types and more.  They will be displayed in the header(top toolbar).
// Notice!!! for localstorage use, there is a need to remove from disable the use_localstorage_for_settings" flag. Or nothing will happened.

// save_load_adapter: { getAllCharts: function(){...}, another implementations...} // Used for save and load from out server. Provide interface to implement like getAllCharts()[return all saved charts]/ removeChart()[remove specific chart]/ savechart() [saving specific chart]/ getChartContent(chartId) [load a chart from server] / getAllStudyTemplated() [get all saved study templated from server].

// settings_adapter: {initialSetings:{...}, setValue: function(key,value){...}, removeValue: function(key){...}}, // allowed to store the charts settings to specific server.

// compare_symbols: [{symbol: 'DAL', title: 'Delta air lines'},{symbol: 'VZ', title: 'Verizon'} ] , // list of all symbols that are will directly added for compare without user adding them.

// additional_symbol_info_fields: [{title:'Ticker', propertyName: 'ticker'}(adds the ticker field to the info window)],...], // Add fields to a symbol info window like sector, exchange etc.

// header_widget_buttons_mode:'fullsize' | 'compact' , //Set buttons display mode, to include text or only icons.

//time_scale: {min_bar_spacing: 10(bars wideness. number greater than 0.),} , // Additional options fields to add more bars on screen. right now has only min_bar_spacing available.

// custom_translate_function: (key,options =>{ if(key === 'Indicators'){ return 'Indicators list';} return null;}) , // Replace most of strings on the chart window. like replace text of 'indicators' to something most suitable like 'iIndicators list'.

// symbol_search_complete: (symbol)=>{
// console.log('selected symbol => ', symbol);
// return new Promise((resolve) => {
//let newSymbol = `the new symbol is - ${symbol}`;
//resolve(newSymbol);
// This function will change the symbol name after setting new symbol. If needed to modify the name before sending to search.
// })
//}, // Allows to override the symbol input to symbol search.


// context_menu: {
//     renderer_factory: (items, params, onDestroy) {
//      console.log(Name of the menu| ', params);
//      console.log('Items composing menu | ', items);
//      return {
//      show:(position) =>{
//      console.log('Context menu | show- position | ', position);
//      }
//      hide: () => {
//      console.log('Context menu | hide');
//      }
//      isShown: () => {
//      console.log('Context menu | isShown');
//      }
// }
//   },
// }, // Allow to retreive the list of items composing most right clicked options. i.e customize the context menu.


// settings_overide: { "linetooltrendline.linecolor":"green"}, //Override any previously setting value like save settings in local storage. it has the biggest priority.


//custom_formatters: { Custom formatters for adjusting the display format of price, date, and time values.
// // Formatting the timezone display.
// timeFormatter: {
//     format: (date) => {
//         const _format_str = '%h:%m';
//         return _format_str
//             .replace('%h', date.getUTCHours(), 2)
//             .replace('%m', date.getUTCMinutes(), 2)
//             .replace('%s', date.getUTCSeconds(), 2);
//     }
// },


// // Formatting the dates on the moving cursor when mouse on.
// dateFormatter: {
//     format: (date) => {
//         return date.getUTCFullYear() + '/' + (date.getUTCMonth() + 1) + '/' + date.getUTCDate();
//     }
// },


// // Formatting the dates displayed on the x axis of the chart(ticks).
// tickMarkFormatter: (date, tickMarkType) => {
//     switch (tickMarkType) {
//         case 'Year':
//             return 'Y' + date.getUTCFullYear();

//         case 'Month':
//             return 'M' + (date.getUTCMonth() + 1);

//         case 'DayOfMonth':
//             return 'D' + date.getUTCDate();

//         case 'Time':
//             return 'T' + date.getUTCHours() + ':' + date.getUTCMinutes();

//         case 'TimeWithSeconds':
//             return 'S' + date.getUTCHours() + ':' + date.getUTCMinutes() + ':' + date.getUTCSeconds();
//     }

//     throw new Error('unhandled tick mark type ' + tickMarkType);
// },


// // Formatting the prices displayed on the y axis.
// priceFormatterFactory: (symbolInfo, minTick) => {
//     if (symbolInfo?.fractional || minTick !== 'default' && minTick.split(',')[2] === 'true') {
//         return {
//             format: (price, signPositive) => {
//                 // return the appropriate format
//             },
//         };
//     }
//     return null; // this is to use default formatter;
// },
// studyFormatterFactory: (format, symbolInfo) => {
//     if (format.type === 'price') {
//         const numberFormat = new Intl.NumberFormat('en-US', { notation: 'scientific' });
//         return {
//             format: (value) => numberFormat.format(value)
//         };
//     }

//     if (format.type === 'volume') {
//         return {
//             format: (value) => (value / 1e9).toPrecision(format?.precision || 2) + 'B'
//         };
//     }

//     if (format.type === 'percent') {
//         return {
//             format: (value) => `${value.toPrecision(format?.precision || 4)} percent`
//         };
//     }

//     return null; // this is to use default formatter;
// },
// }

//};