import configurations from '../resources/configurations.json';

class StockAPI {

    constructor(logHandler, backToLogin) {
        this.username = '';
        this.setIsLoggedIn = logHandler;
        this.backToLogin = backToLogin;
        this.server_address = configurations.proxy_http_address;
    }


    //============================= HTTP REQUESTS METHODS =============================

    async requestGeneric({method, url, traceBack, requestData = null, withAuth = true, useProxy = false}) {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };

            if (withAuth) {
                headers['Authorization'] = `Bearer ${localStorage.getItem('auth-token')}`;
            }

            const options = {
                method,
                headers,
                body: requestData ? JSON.stringify(requestData) : null,
            };
            const response = await fetch(url, options);
            this.handleLogCode(response.status, `${method}\n${traceBack}`);

            if (response.ok) {
                return await response.json();
            } else {
                return {code: response.status, msg: response.statusText};
            }
        } catch (error) {
            return {code: 500, msg: error.message};
        }
    }

    async postWithoutAuth(url, traceBack, useProxy = false, requestData = null) {
        return await this.requestGeneric({method: 'POST', url, traceBack, requestData, withAuth: false, useProxy});
    }

    async postWithAuth(url, traceBack, useProxy = false, requestData = null) {
        return await this.requestGeneric({method: 'POST', url, traceBack, requestData, withAuth: true, useProxy});
    }

    async getWithAuth(url, traceBack, useProxy = false, requestData = null) {
        return await this.requestGeneric({method: 'GET', url, traceBack, requestData, withAuth: true, useProxy});
    }

    async putWithAuth(url, traceBack, useProxy = false, requestData = null) {
        return await this.requestGeneric({method: 'PUT', url, traceBack, requestData, withAuth: true, useProxy});
    }

    async deleteWithAuth(url, traceBack, useProxy = false, requestData = null) {
        return await this.requestGeneric({method: 'DELETE', url, traceBack, requestData, withAuth: true, useProxy});
    }

    //===================================================================================


    //============================= LOGIN EXISTED USER =============================

    async login(username, password) {
        try {
            const url = `${this.server_address}/login`;
            const optionalData = {
                username: username,
                password: password
            };
            const data = await this.postWithoutAuth(url, "login", true, optionalData);
            if (data?.code === 200) {

                // Load the data from the server response.
                //get the jasoned data from the server response.
                // Store the received access token in localStorage
                localStorage.setItem('auth-token', data.access_token);
                // Store the received user role in localStorage
                localStorage.setItem('user-role', data.role);
                // Store the received user name in localStorage
                localStorage.setItem('user-name', data.username);
                localStorage.setItem('isLoggedIn', true);
                return {"code": "200", "username": `${data.username}`}
            } else {
                //check the value of the status code in the error messgae right after the ':'.
                //convert to int.
                switch (data?.code) {
                    case 401:
                        // Server returned a status code of 401
                        return {"code": "401", "msg": "Unauthorized access."};
                    case 403:
                        this.backToLogin();
                        break;
                    case 500:
                        // Server returned a status code of 500
                        return {"code": "500", "msg": "Internal Server Error."};

                    default:
                        // Server returned a different status code
                        return {"code": "500", "msg": "Unknown Error."};
                }
            }

        } catch (error) {
            //check the value of the status code in the error messgae right after the ':'.
            //convert to int.
            switch (parseInt(error.message.split(':')[1])) {
                case 401:
                    // Server returned a status code of 401
                    return {"code": "401", "msg": "Unauthorized access."};
                case 500:
                    // Server returned a status code of 500
                    return {"code": "500", "msg": "Internal Server Error."};

                default:
                    // Server returned a different status code
                    return {"code": "500", "msg": "Unknown Error."};
            }
        }
    }

    handleLogCode(code, traceBack) {
        if (this.setIsLoggedIn !== null) {
            if (code === 403 || code === "403") {
                this.setIsLoggedIn(false);
                this.backToLogin();
            }
        }
    }

    async verifyEmail(username) {

        try {
            const url = `${this.server_address}/verifyEmail`;
            const optionalData = {
                username: username
            };
            return await this.postWithAuth(url, "verifyEmail", true, optionalData);
        } catch (error) {
            return {code: 500, msg: error};
        }

    }

    async savePassword(username, newPassword) {
        try {
            const url = `${this.server_address}/savePassword`;
            const optionalData = {
                username: username,
                password: newPassword
            };
            return await this.postWithAuth(url, "savePassword", true, optionalData);
        } catch (error) {
            return {code: 500, msg: error};
        }
    }

    async verifyToken(username, token) {
        try {
            const url = `${this.server_address}/verifyToken`;
            const optionalData = {
                username: username,
                token: token
            };
            return await this.postWithAuth(url, "verifyToken", true, optionalData);
        } catch (error) {
            return {code: 500, msg: error};
        }
    }

    //===================================================================================


    //============================= NEW USER ACCESS REQUEST =============================

    async request_access(email, firstName, lastName, phoneNumber, username, password) {
        try {
            const url = `${this.server_address}/requestAccess`;
            const optionalData = {
                username: username,
                password: password,
                email: email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber
            };
            return await this.postWithoutAuth(url, "request_access", true, optionalData);
        } catch (e) {
            return {code: 500, msg: 'Server error'};
        }
    }

    async rejectRequest(requestEmail) {
        try {

            const url = `${this.server_address}/handleRequest`;
            const optionalData = {
                email: requestEmail,
                decision: 'reject'
            };
            return await this.postWithAuth(url, "rejectRequest", true, optionalData);
        } catch (error) {
            return false;
        }
    }

    async approveRequest(requestEmail) {
        try {
            const url = `${this.server_address}/handleRequest`;
            const optionalData = {
                email: requestEmail,
                decision: 'approve'
            };
            return await this.postWithAuth(url, "approveRequest", true, optionalData);
        } catch (error) {
            return false;
        }
    }

    async fetchRequestsData() {
        try {
            const role = localStorage.getItem('user-role');
            const url = `${this.server_address}/requestsData`;
            const optionalData = {
                role: role
            };
            const response = await this.postWithAuth(url, "fetchRequestsData", true, optionalData);
            if (response.code !== 200) {
                return [];
            }
            return response.list;
            // Handle the response as needed
        } catch (error) {
            throw new Error(`HTTP error: ${error.message}`);
        }
    }

    //===================================================================================


    //============================= MARKERING CHART CONTROL =============================

    async insertMark(name, interval, range, min_interval_width, max_interval_width, angle_in, angle_out, action_in, action_out, normal_range_start, normal_range_end) {
        const url = `${this.server_address}/insert_mark`;
        const requestData = {
            name: name,
            interval: interval,
            range: range,
            angle_in: angle_in,
            angle_out: angle_out,
            action_in: action_in,
            action_out: action_out,
            normal_range_start: normal_range_start,
            normal_range_end: normal_range_end,
            max_interval_width: max_interval_width,
            min_interval_width: min_interval_width
        };
        try {
            return await this.postWithAuth(url, "insertMark", true, requestData);
        } catch (error) {
            throw error;
        }
    }

    async deleteMark(r_name, r_interval, r_range) {
        const url = `${this.server_address}//remove_mark`;
        const requestData = {
            name: r_name,
            interval: r_interval,
            range: r_range
        };
        try {
            return this.deleteWithAuth(url, "deleteMark", true, requestData);
        } catch (error) {
            throw error;
        }
    }

    async updateMark(name, interval, range, normal_range, max_interval_width, min_interval_width) {
        const url = `${this.server_address}/update_mark`;
        const requestData = {
            name: name,
            interval: interval,
            range: range,
            normal_range: normal_range,
            max_interval_width: max_interval_width,
            min_interval_width: min_interval_width
        };
        try {
            return await this.putWithAuth(url, "updateMark", true, requestData);
        } catch (error) {
            throw error;
        }
    }

    async updateAllMarksInOneRequest(changes) {
        const url = `${this.server_address}/update_all_marks`;

        const requestData = Object.entries(changes).map(([id, change]) => ({
            ...change
        }));
        try {
            return await this.putWithAuth(url, "updateAllMarksInOneRequest", true, requestData);
        } catch (error) {
            throw error;
        }
    }

    async updateMultipleMarks(changes) {
        const promises = []; // This will store all the promises from the updateMark calls

        for (const [stockName, change] of Object.entries(changes)) {
            const {
                interval,
                range,
                normal_range,
                max_interval_width,
                min_interval_width
            } = change;

            // Push the promise returned by updateMark into the promises array
            promises.push(
                this.updateMark(
                    stockName,
                    interval,
                    range,
                    normal_range,
                    max_interval_width,
                    min_interval_width
                )
            );
        }

        // Wait for all promises to complete
        try {
            await Promise.all(promises);

        } catch (error) {
            return {code: 500, msg: `Error updating some marks:${error}`}
        }
    }

    async getMark(name, interval, range) {
        const url = `${this.server_address}/get_mark`;
        const requestData = {
            name: name,
            interval: interval,
            range: range
        };
        try {
            return await this.getWithAuth(url, "getMark", true, requestData);
        } catch (error) {
            return {code: 500, msg: `Error fetching mark : ${error}`}
        }
    }

    async get_all_marks() {
        const url = `${this.server_address}/get_all_marks`;
        try {
            const response = await this.postWithAuth(url, "get_all_marks", true);
            if (response.code !== 200) {
                return [];
            }
            return response.data;
        } catch (error) {
            return {code: 500, msg: `Error fetching all marks: ${error}`}

        }
    }

    //===================================================================================


    //============================= USERS MANAGEMENT CONTROL =============================

    async fetchUsersData() {
        try {
            const url = `${this.server_address}/usersData`;
            const optionalData = {
                role: localStorage.getItem('user-role')
            };
            const response = await this.postWithAuth(url, "fetchUsersData", true, optionalData);
            if (response.code !== 200) {
                return [];
            }
            return response.list;
            // Handle the response as needed
        } catch (error) {
            return [];
        }
    }

    async addUser(newUser) {
        try {
            const url = `${this.server_address}/addUser`;
            const optionalData = {
                username: newUser.username,
                password: newUser.password,
                email: newUser.email,
                salt: newUser.salt,
                role: newUser.authName
            };
            return this.postWithAuth(url, "addUser", true, optionalData);
        } catch (error) {
            return false;
        }
    }

    async updateUser(editedUser) {
        try {
            const url = `${this.server_address}/updateUser`;
            const optionalData = {
                username: editedUser.username,
                salt: editedUser.salt,
                role: editedUser.authName
            };
            return this.putWithAuth(url, "updateUser", true, optionalData);
        } catch (error) {
            return false;
        }
    }

    async removeUser(newUser) {
        try {
            const url = `${this.server_address}/removeUser`;
            const optionalData = {
                username: newUser.username
            };
            //Send the request and get the result from the promise returned by the fetch function.
            return this.deleteWithAuth(url, "deleteMark", true, optionalData);
        } catch (error) {
            return false;
        }
    }

    //===================================================================================


    //============================= CHARTS DATA UPDATE =============================

    async fetchStocksData(stock, intervals, ranges) {
        try {
            const url = `${this.server_address}/charts_data/${stock}/${intervals}/${ranges}`;
            return await this.postWithAuth(url, "fetchStocksData", true);
        } catch (error) {
            return [];
        }
    }

    async fetchStockData(stock, interval, timeRange) {
        try {
            const url = `${this.server_address}/chart_data/${stock}/${interval}/${timeRange}`;
            return await this.postWithAuth(url, "fetchStockData", true);
        } catch (error) {
            return [];
        }
    }

    async isStockLegal(stock) {
        try {
            const url = `${this.server_address}/verifySymbol`;
            const optionalData = {
                name: stock
            };
            return await this.postWithAuth(url, "isStockLegal", true, optionalData);
        } catch (error) {
            return {code: 500, msg: error};
        }
    }

    async updateChartsData(stock, intervals, ranges) {
        try {
            const response = await this.fetchStocksData(stock, intervals, ranges);
            let chartDataList = [];

            if (response.code !== 200) {
                return [[], [], [], [], [], []];
            }
            const allData = response.list;
            for (const key in allData) {
                if (allData[key].length > 0) {
                    // processing the data to fit the chart
                    const chartData = processData(allData[key], "updateChartsData");
                    chartDataList.push(chartData);
                } else {
                    chartDataList.push([]);
                }
            }

            return chartDataList;
        } catch (error) {
            return [[], [], [], [], [], []];
        }
    }

    async updateChartData(stock, interval, range) {
        try {
            const response = await this.fetchStockData(stock, interval, range);

            if (response.code !== 200) {
                return [];
            }
            const data = response.list;

            if (data.length > 0) {
                // processing the data to fit the chart
                return processData(data, "updateChartsData");
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    }


    async updateUserPreferences(index, fieldName, newValue) {
        //send a post request to the server to update the user preferences on the db.

        const url = `${this.server_address}/updateUserChartPreferences`;
        const requestData = {
            index: index,
            fieldName: fieldName,
            newValue: newValue
        };
        try {
            return await this.putWithAuth(url, "updateUserPreferences", true, requestData);
        } catch (error) {
            throw error;
        }

    }

    //===================================================================================


    //============================= USER MENU OPTIONS =============================

    async closeProgram() {
        const url = `${this.server_address}/logout`;
        const optionalData = {
            username: localStorage.getItem('user-name')
        };
        const data = await this.postWithAuth(url, "closeProgram", true, optionalData);
        if (data?.code === 200) {
            //get the content from the response.
            localStorage.removeItem('auth-token');
            localStorage.removeItem('isLoggedIn');
            return {'code': 200, 'msg': data.msg};
        } else {
            return {'code': data?.code, 'msg': data.msg};
        }

    }

    async shutDown() {

        try {
            const url = `${this.server_address}/shutdown`;
            const optionalData = {
                username: localStorage.getItem("user-name")
            };
            return this.postWithAuth(url, "shutDown", true, optionalData);
        } catch (error) {
            return false;
        }
    }

    //===================================================================================

}


//============================= HELPER FUNCTIONS =============================


export function processData(data, caller) {
    if (!data || data.length === 0) {
        return [];
    }
    const retData = data.map((c) => {
        return {
            time: date_to_sec(new Date(c.date)),
            high: c.high,
            low: c.low,
            open: c.open,
            close: c.close,
        }
    });
    return retData;
}

export function convertToSeconds(value, isInterval) {
    if (value == null) {
        console.error('Cannot convert null value into seconds');
        return 0;
    }
    const intervalOptions = {
        "1": 60,     // 1 minute
        "2": 120,    // 2 minutes
        "5": 300,    // 5 minutes
        "15": 900,   // 15 minutes
        "30": 1800,  // 30 minutes
        "60": 3600,  // 60 minutes or 1 hour
        "90": 5400,  // 90 minutes
        "1D": 86400, // 1 day
        "5D": 432000,// 5 days
        "1W": 604800,// 1 week
        "1M": 2592000,// 1 month
        "3M": 7776000// 3 months
    };
    const rangeOptions = {
        "1D": 86400,   // 1 day
        "5D": 432000,  // 5 days
        "1M": 2592000, // 1 month
        "3M": 7776000, // 3 months
        "6M": 15552000,// 6 months (approximated as 6M)
        "12M": 31104000,// 1 year (12 months)
        "24M": 62208000,// 2 years (24 months)
        "60M": 155520000,// 5 years (60 months)
        "120M": 311040000,// 10 years (120 months)
        "max": -1       // All time (handled specially in your logic)
    };

    if (isInterval) {
        // Calculate the number of seconds based on the unit
        if (intervalOptions.hasOwnProperty(value)) {
            return intervalOptions[value];
        }
    } else {
        if (rangeOptions.hasOwnProperty(value)) {
            return rangeOptions[value];
        }
    }
    throw new Error(`Invalid unit value: ${value}`);
}

export function isIntervalValidForRange(intervalInSeconds, rangeInSeconds) {
    if (intervalInSeconds === 60) { // 1m
        return rangeInSeconds <= 7 * 86400; // 1 week
    } else if (intervalInSeconds === 16200) { // 5m, 15m, 30m,60m,90m
        return rangeInSeconds < 60 * 86400; //untill month
    } else if (intervalInSeconds === 300 || intervalInSeconds === 900 || intervalInSeconds === 1800
        || intervalInSeconds === 5400) {
        return rangeInSeconds <= 60 * 86400; //2 monthes untill
    } else if (intervalInSeconds === 3600) { // 1h
        return rangeInSeconds <= 730 * 86400;
    }
    return true;
}

export function date_to_sec(date) {
    return Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
    ) / 1000;
}

//===================================================================================


export default StockAPI;
