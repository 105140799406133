import React from 'react';
import logo from '../resources/logo_images_2/logo1024x512.png';
import '../styles/NotFountComponent.css';
function NotFoundComponent() {
    return (
        <div className="not-found-container">
            <div className="content-center">
                <img className="not-found-logo" src={logo} alt="Company Logo" />
                <h2>404 - Page Not Found</h2>
                <p>Sorry, the page you're looking for doesn't exist.</p>
                <button className="home-button" onClick={() => window.location.href = "#/home"}>Go to Home</button>
            </div>
        </div>
    );
}

export default NotFoundComponent;
