// CollapsibleList.js


import React from 'react';
import CollapsiblePanel from './CollapsiblePanel';
import '../styles/CollapsibleList.css';
import {translate} from "./App";

const CollapsibleList = ({
                             items,
                             handlers,
                             modifiable,
                             handleMarkClick,
                             handleMarkDoubleClick,
                             selectedMark,
                             displayedMark
                         }) => {

    return (
        <div className="collapsible-list">
            {items.map((item, index) => {
                const curr_id = `${item.name}-${item.interval}-${item.range}`;
                return (
                    <CollapsiblePanel key={index}
                                      index={index}
                                      name={item.name}
                                      interval={item.interval}
                                      range={item.range}
                                      isSelectedMark={(selectedMark === curr_id)}
                                      isDisplayedMark={(displayedMark === curr_id)}
                                      handleMarkClick={() => handleMarkClick(curr_id)}
                                      handleMarkDoubleClick={() => handleMarkDoubleClick(curr_id)}>
                        {
                            <div className="user-edit-container">
                                <div className="input-container">
                                    <label>{translate('Symbol Name')}:</label>
                                    {modifiable[index]['name'] ?
                                        (<input
                                            className={'mark-input'}
                                            type={'number'}
                                            value={item.name}
                                            onChange={(e) => handlers['name'](curr_id, Number(e.target.value))}
                                        />)
                                        :
                                        (<span className="item-value">{item.name}</span>)
                                    }
                                </div>
                                <div className="input-container">
                                    <label>{translate('Interval')}:</label>
                                    {modifiable[index]['interval'] ?
                                        (<input
                                            className={'mark-input'}
                                            type={'number'}
                                            value={item.interval}
                                            onChange={(e) => handlers['interval'](curr_id, Number(e.target.value))}
                                        />)
                                        :
                                        (<span className="item-value">{item.interval}</span>)
                                    }
                                </div>
                                <div className="input-container">
                                    <label>{translate('Range')}:</label>
                                    {modifiable[index]['range'] ?
                                        (<input
                                            className={'mark-input'}
                                            type={'number'}
                                            value={item.range}
                                            onChange={(e) => handlers['range'](curr_id, Number(e.target.value))}
                                        />)
                                        :
                                        (<span className="item-value">{item.range}</span>)
                                    }
                                </div>
                                <div className="two-val-container">
                                    <div className="input-container">
                                        <label>{translate('Normal Range Start')}:</label>
                                        {modifiable[index]['normal_range_start'] ?
                                            (<input
                                                className={'mark-input'}
                                                type={'number'}
                                                value={item.normal_range_start}
                                                onChange={(e) => handlers['normal_range_start'](curr_id, Number(e.target.value))}
                                            />)
                                            :
                                            (<span className="item-value">{item.normal_range_start}</span>)
                                        }
                                    </div>
                                    <div className="input-container">
                                        <label>{translate('Normal Range End')}:</label>
                                        {modifiable[index]['normal_range_end'] ?
                                            (<input
                                                className={'mark-input'}
                                                type={'number'}
                                                value={item.normal_range_end}
                                                onChange={(e) => handlers['normal_range_end'](curr_id, Number(e.target.value))}
                                            />)
                                            :
                                            (<span className="item-value">{item.normal_range_end}</span>)
                                        }
                                    </div>
                                </div>
                                <div className="two-val-container">
                                    <div className="input-container">
                                        <label>{translate('Min Interval Width')}:</label>
                                        {modifiable[index]['min_interval_width'] ?
                                            (<input
                                                className={'mark-input'}
                                                type={'number'}
                                                value={item.min_interval_width}
                                                onChange={(e) => handlers['min_interval_width'](curr_id, Number(e.target.value))}
                                            />)
                                            :
                                            (<span className="item-value">{item.min_interval_width}</span>)
                                        }
                                    </div>
                                    <div className="input-container">
                                        <label>{translate('Max Interval Width')}:</label>
                                        {modifiable[index]['max_interval_width'] ?
                                            (<input
                                                className={'mark-input'}
                                                type={'number'}
                                                value={item.max_interval_width}
                                                onChange={(e) => handlers['max_interval_width'](curr_id, Number(e.target.value))}
                                            />)
                                            :
                                            (<span className="item-value">{item.max_interval_width}</span>)
                                        }
                                    </div>
                                </div>
                                <div className="list-input-container-angle">
                                    <label>{translate('Angle in')}:</label>
                                    {modifiable[index]['angle_in'] ?
                                        (<input
                                            className={'mark-input'}
                                            type={'number'}
                                            value={item.angle_in}
                                            onChange={(e) => handlers['angle_in'](curr_id, Number(e.target.value))}
                                        />)
                                        :
                                        (<span className="item-value">{item.angle_in}</span>)
                                    }
                                    {modifiable[index]['action_in'] ?
                                        (
                                            <select className="mark-input-line angle select" name="action_in"
                                                    onChange={(e) => handlers['action_in'](curr_id, e.target.value)}
                                                    value={item.action_in}>
                                                <option value="gt">{translate('gt')}</option>
                                                <option value="gte">{translate('gte')}</option>
                                                <option value="eq">{translate('eq')}</option>
                                                <option value="lte">{translate('lte')}</option>
                                                <option value="lt">{translate('lt')}</option>
                                            </select>
                                        ) : (<span className="item-value">{item.action_in}</span>)}
                                </div>
                                <div className="list-input-container-angle">
                                    <label>{translate('Angle out')}:</label>
                                    {modifiable[index]['angle_out'] ?
                                        (<input
                                            className={'mark-input'}
                                            type={'number'}
                                            value={item.angle_out}
                                            onChange={(e) => handlers['angle_out'](curr_id, Number(e.target.value))}
                                        />)
                                        :
                                        (<span className="item-value">{item.angle_out}</span>)
                                    }
                                    {modifiable[index]['action_out'] ?
                                        (
                                            <select className="mark-input-line angle select" name="action_out"
                                                    onChange={(e) => handlers['action_out'](curr_id, e.target.value)}
                                                    value={item.action_out}>
                                                <option value="gt">{translate('gt')}</option>
                                                <option value="gte">{translate('gte')}</option>
                                                <option value="eq">{translate('eq')}</option>
                                                <option value="lte">{translate('lte')}</option>
                                                <option value="lt">{translate('lt')}</option>
                                            </select>
                                        ) : (<span className="item-value">{item.action_out}</span>)}
                                </div>
                            </div>
                        }
                    </CollapsiblePanel>
                )
            })}
        </div>
    );
};

export default CollapsibleList;


// return (
//                    <div key={key} className="list-item">
//                        <span className="item-key">{key.charAt(0).toUpperCase() + key.substring(1).replaceAll('_', ' ')}: </span>
//                        {modifiable[index][key] ?
//                            (key !== 'action_in' && key !== 'action_out' ?
//                                    (<input
//                                        className={'mark-input'}
//                                        type={'number'}
//                                        value={value}
//                                        onChange={(e) => handlers[key](curr_id, Number(e.target.value))}
//                                    />) :
//                                    (
//                                        <select className="mark-input" name="action_in"
//                                                onChange={(e) => handlers[key](curr_id, e.target.value)}
//                                                value={value}>
//                                            >
//                                            <option value="gt">{translate('gt')}</option>
//                                            <option
//                                                value="gte">{translate('gte')}</option>
//                                            <option value="eq">{translate('eq')}</option>
//                                            <option value="lte">{translate('lte')}</option>
//                                            <option value="lt">{translate('lt')}</option>
//                                        </select>
//                                    )
//
//                            ) : (<span className="item-value">{value}</span>)}
//                    </div>
//                )