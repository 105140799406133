import React, {useEffect, useRef,} from 'react';
import logo from '../resources/logo_images_2/logo1024x512.png';
import arrow from '../resources/arraws/no_background_ancient_arrow.png';
import '../styles/HomePage.css';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import LanguageSelector from './LanguageSelector';
import {isHebrew, translate} from "./App";
import {useAdvancedChart} from "./useTradingViewChart";
import configurations from "../resources/configurations.json";

function HomePage() {

    const chartContainerRef = useRef();
    const chart = React.useMemo(() => React.createRef(), []);
    const address = configurations.proxy_http_address;


    const createMarkup = (htmlString) => {
        return {__html: htmlString};
    };

    useEffect(() => {
        if (!chart.current && chartContainerRef.current) {
            chart.current = useAdvancedChart(`EURUSD=X?${-1}`, '15', '1M', chartContainerRef, true, address, true);
        }
        return () => {
            if (chart.current) {
                chart.current.remove();
                chart.current = null;
            }
        };
    }, []);
    const changeLocale = (newLocale) => {
        localStorage.setItem('appLocale', newLocale);
        window.location.reload(); // Reload the page to apply the new locale

    };


    const move_to_login = () => {
        window.location.href = "#/login";
    };
    return (
        <div className="body-container">
            <div className="main-container">
                <div className={'tops'}>
                    <div className="top-left">
                        <button className="login-button"
                                onClick={move_to_login}>{translate('Login')}</button>
                    </div>
                    <div className="top-right">
                        <LanguageSelector onLocaleChange={changeLocale}/>
                    </div>
                </div>
                <img className="home-logo" src={logo} alt="Company Logo"/>

                <div className='space'></div>

                <div className="scroll-indicator">
                    {translate('Scroll down for more')}
                    <img src={arrow} alt="Scroll Down Arrow" className="scroll-arrow"/>
                </div>
                <div className='long-space'></div>
                <div className="about-section" style={{direction: isHebrew() ? 'rtl' : 'ltr'}}>
                    <h2>{translate('About Us')}</h2>
                    <p dangerouslySetInnerHTML={createMarkup(translate('paragraph1'))}></p>
                    <div className='space'></div>
                    <p dangerouslySetInnerHTML={createMarkup(translate('paragraph2'))}></p>
                    <div className='space'></div>
                    <p dangerouslySetInnerHTML={createMarkup(translate('paragraph3'))}></p>
                </div>
                <div className="scroll-indicator">
                    <img src={arrow} alt="Scroll Down Arrow" className="scroll-arrow"/>
                </div>
                <div className='space'></div>
                <div className='chart-logo-container'>
                    <div className="home-chart-container" ref={chartContainerRef}></div>
                    <div className='chart-analysis-section' style={{direction: isHebrew() ? 'rtl' : 'ltr'}}>
                        <h2>{translate('Advanced Charting & Analysis Tools')}</h2>
                        <p>
                            {translate('Charting Description1')}
                            <a href="https://www.tradingview.com/symbols/BTCUSD/" target="_blank" rel="noopener noreferrer"
                            > btc usd </a>
                            {translate('Charting Description2')}
                        </p>
                    </div>

                </div>
                <div className="contact-section">
                    <h2>Contact Us</h2>
                    <div className="contact-item">
                        <MailIcon className="contact-icon"/>
                        <a href="mailto:bardamri1702@gold770.com">bardamri1702@gold770.com</a>
                    </div>
                    <div className="contact-item">
                        <PhoneIcon className="contact-icon"/>
                        <div
                            className="contact-name">{translate('Yeruslav Goldman')}:
                        </div>
                        <a href="tel:+0524491828">0524491828</a>
                    </div>
                    <div className="contact-item">
                        <PhoneIcon className="contact-icon"/>
                        <div className="contact-name">{translate('Steven Dichne')}:
                        </div>
                        <a href="tel:+0524327687">0524327687</a>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}

export default HomePage;
