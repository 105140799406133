import React, {useState, useMemo, useRef, useEffect, useCallback, useContext, memo} from 'react';
import ChartRow from './ChartRow';
import '../styles/DashboardComponent.css';
import StockAPI from './StockApi';
import ControlMenu from './ControlMenu';
import logo from '../resources/logo_images_2/logo800.png';
import {useNavigate} from 'react-router-dom';
import configurations from "../resources/configurations.json";
import LanguageSelector from "./LanguageSelector";
import {useSelector, useDispatch} from 'react-redux';
import {
    setStockName,
    addLog,
    loadMarkers,
    setDisplayMode,
    setChartsData
} from '../redux/dataBankReducer';


const RefreshContext = React.createContext();

export const useRefresh = () => {
    return useContext(RefreshContext);
};

const WebSocketContext = React.createContext();


function DashboardComponent({handleLogCode}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const backToLogin = useCallback(() => {
        navigate('/login');
    }, [navigate]);
    const stockApi = useMemo(() => new StockAPI(handleLogCode, backToLogin), [handleLogCode, backToLogin]);
    const visible = React.useMemo(() => Array(16).fill(false).map(() => React.createRef()), []);
    const [stockHeader, setStockHeader] = useState('AAPL');
    const [currency1Header, setCurrency1Header] = useState('EUR');
    const [currency2Header, setCurrency2Header] = useState('USD');
    const [message, setMessage] = useState('');
    const [textColor, setTextColor] = useState("red");
    const [zoomedInIndex, setZoomedInIndex] = useState(-1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerWidth);
    const [refreshNeeded, setRefreshNeeded] = useState(true);
    const header = useRef(null);
    const body = useRef(null);
    const headerInView = useRef(true);
    const [containerSizes, setContainerSizes] = useState({width: 0, height: 0});
    const [ws, setWs] = useState(null);
    const [markInit, setMarksInit] = useState(false)
    const fetchMarksData = useCallback(async () => {
        try {
            if (stockApi && !markInit) {
                // Load the marking data from the server.
                const marksData = await stockApi.get_all_marks();
                const newMarkers1 = {};
                if (marksData && marksData.length > 0) {
                    marksData.forEach(mark => {
                        newMarkers1[mark._id] = {
                            name: mark.name,
                            interval: mark.interval,
                            range: mark.range,
                            normal_range_start: mark.normal_range_start,
                            normal_range_end: mark.normal_range_end,
                            angle_in: mark.angle_in,
                            angle_out: mark.angle_out,
                            action_in: mark.action_in,
                            action_out: mark.action_out,
                            max_interval_width: mark.max_interval_width,
                            min_interval_width: mark.min_interval_width
                        };
                    });
                    dispatch(loadMarkers(newMarkers1))
                }
                setMarksInit(true);
            }
        } catch (error) {
            dispatch(addLog([`got an error in fetchMarksData: ${error}`]));
        }
    }, [stockApi]);
    const [symbolChangedFlag, setSymbolChangedFlag] = useState(false);
    const stockName = useSelector(state => state.dataBank.stockName); // Accessing stockName from Redux store
    const ranges = useSelector(state => state.dataBank.ranges);
    const chartsSettings = useSelector(state => state.dataBank.chartsSettings);
    const stocks = useSelector(state => state.dataBank.stocks);
    const currencies = useSelector(state => state.dataBank.currencies);
    const displayMode = useSelector(state => state.dataBank.displayMode); // Assuming you have this in your Redux state


    const logout = () => {
        stockApi.closeProgram().then(_ => dispatch(addLog(['Closed program', 0]))); // Assuming logType 0 is for debug
        localStorage.removeItem("user-role");
        localStorage.removeItem("user-name");
        localStorage.removeItem("user-token");
        localStorage.removeItem('isLoggedIn');
        navigate('/login');
    };

    if (ranges.length === 0) {
        logout();

    }

    useEffect(() => {
        if (displayMode === 'stock' && stockHeader.length > 0 && stockName !== stockHeader) {
            dispatch(setStockName(stockHeader)); // Dispatching action to update stockName in the store
            setSymbolChangedFlag(prevFlag => !prevFlag);
        } else if (displayMode === 'currency' && currency1Header.length !== 0 && currency2Header.length !== 0) {
            const newStockName = `${currency1Header}${currency2Header}=X`;
            if (stockName !== newStockName) {
                dispatch(setStockName(newStockName)); // Dispatching action to update stockName in the store
                setSymbolChangedFlag(prevFlag => !prevFlag);
            }
        }
    }, [currency1Header, currency2Header, stockHeader, displayMode, stockName, dispatch]);

    // Setting the ws connection and resize window handler.
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('beforeunload', (e) => {
            e.preventDefault();
            logout();
        });

        const socketUrl = configurations.proxy_ws_address + "?token=" + encodeURIComponent(localStorage.getItem('auth-token'));
        const socket = new WebSocket(socketUrl);

        socket.onopen = () => dispatch(addLog(['Connected to server through ws', 0]));
        socket.onmessage = (event) => {
            try {
                const {index, messageType, data} = JSON.parse(event.data);
                if (messageType === 0) {
                    dispatch(addLog(['Received logs data from server', 0]));
                } else if (messageType === 1 && index !== -1) {
                    dispatch(setChartsData([index, data]));
                }
            } catch (e) {
                dispatch(addLog([`Received an error during charts update. Error: ${e}`, 2]));
                logout();
            }
        };
        socket.onerror = (error) => dispatch(addLog([`Error on ws. Error: ${error}`, 1]));
        socket.onclose = () => dispatch(addLog(['Disconnected from server via ws.', 1]));
        setWs(socket);
        fetchMarksData().then(_ => dispatch(addLog(['Finished loading marking data.', 0]))).catch(err => dispatch(addLog([`Failed loading marking data. Error: ${err}`, 2])));

        return () => {
            window.removeEventListener('resize', handleResize);
            socket.close();
        };
    }, []);


    useEffect(() => {
        const percentage_of_width_for_charts = 1;
        const container_width = (windowWidth / chartsSettings.chartsInRow) * percentage_of_width_for_charts;
        setContainerSizes({width: container_width - 20, height: 360});
    }, [windowWidth, windowHeight, chartsSettings]);

    const changeLocale = (newLocale) => {
        localStorage.setItem('appLocale', newLocale);
        window.location.reload(); // Reload the page to apply the new locale
    };

    const setErrorMessage = (message) => {
        setTextColor("red");
        setMessage(message);
        setTimeout(() => {
            setMessage("");
            setTextColor("green");
        }, 3000);
    };


    /* Handlers */

    const handleDisplayModeChange = (e) => {
        try {
            const newMode = e.target.checked ? 'currency' : 'stock';
            dispatch(setDisplayMode(newMode));

            let newStockName = '';
            if (newMode === 'currency') {
                const currency1 = currency1Header.length === 0 ? 'EUR' : currency1Header;
                const currency2 = currency2Header.length === 0 ? 'USD' : currency2Header;
                newStockName = `${currency1}${currency2}=X`;
                setCurrency1Header(currency1);
                setCurrency2Header(currency2);
            } else {
                newStockName = stockHeader.length === 0 ? 'AAPL' : stockHeader;
                setStockHeader(newStockName);
            }
            dispatch(setStockName(newStockName));
            dispatch(addLog([`Stock name was changed to : ${newStockName}`, 0]));

        } catch (error) {
            dispatch(addLog([`got an error in handleDisplayModeChange: ${error}`, 2]));
        }
    };

    const handleZoomIn = async (i) => {
        try {
            setZoomedInIndex(prevIndex => prevIndex === i ? -1 : i);
            setRefreshNeeded(prevState => !prevState);
        } catch (error) {
            dispatch(addLog([`got an error in handleZoomIn: ${error}`, 2]));
        }
    };


    /* Rendering functions */

    function handleSymbolChanged(type, value) {
        switch (type) {
            case 'stock':
                setStockHeader(value);
                break;
            case 'currency1':
                setCurrency1Header(value);
                break;
            case 'currency2':
                setCurrency2Header(value);
                break;
        }
    }

    const renderHeader = () => {
        return (
            <div className="App-header">
                <div className={'tops'}>
                    <div className="top-left">
                        <LanguageSelector onLocaleChange={changeLocale}/>
                    </div>
                </div>
                <div className={'home-logo-container'}>
                    <img className="logo" src={logo} alt="Company Logo"/>
                </div>
                <div className="sticky-stock-name">
                    <div className="toggle-switch">
                        <label>
                            <input type="checkbox"
                                   checked={displayMode === 'currency'}
                                   onChange={handleDisplayModeChange}/>
                            <span className="toggle-slider">
                            <span
                                className={`inside-icon${displayMode === 'currency' ? ' inside-currency' : ' inside-stock'}`}>
                                {displayMode === 'currency' ? <i className="fa-solid fa-dollar-sign"/> :
                                    <i className="fa-solid fa-money-bill-trend-up"/>}
                            </span>
                        </span>
                        </label>
                    </div>
                    <div className="header-controls">
                        {displayMode === 'stock' ? (
                            <div className="header-stock-selector">
                                <select className={'header-select'}
                                        onChange={(e) => handleSymbolChanged('stock', e.target.value)}
                                        value={stockHeader}>
                                    {stocks.map((stockName, index) => (
                                        <option key={index} value={stockName}>{stockName}</option>))}
                                </select>
                            </div>
                        ) : (
                            <div className="header-currency-selector">
                                <select className={'header-select'}
                                        onChange={(e) => handleSymbolChanged('currency1', e.target.value)}
                                        value={currency1Header}>
                                    {currencies.map((key, index) => (<option key={index} value={key}>{key}</option>))}
                                </select>
                                <select className={'header-select'}
                                        onChange={(e) => handleSymbolChanged('currency2', e.target.value)}
                                        value={currency2Header}>
                                    {currencies.map((key, index) => (<option key={index} value={key}>{key}</option>))}
                                </select>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderBody = () => {

        return (<div className="body-container">
            {renderChartRows()}

            <div className="chart-controls" style={{display: zoomedInIndex === -1 ? 'flex' : 'none'}}>
                <div className="space"></div>
                <div className="message-box" style={{color: textColor}}>{message}</div>
                <div className="space"></div>
            </div>
        </div>);
    };

    const renderChartRows = () => {
        // Access Redux state for charts settings
        try {
            const rows = [];
            const totalRows = 4; // Adjust based on your application's needs

            for (let i = 0; i < totalRows; i++) {
                const startIndex = i * 4;
                const endIndex = startIndex + 4; // Assuming 4 charts per row

                // Use Redux state for determining visibility
                const isRowVisible = i + 1 <= Math.ceil(chartsSettings.chartsInTotal / chartsSettings.chartsInRow);
                const indexes = [];
                const chartVisibility = [];

                for (let j = startIndex; j < endIndex; j++) {
                    indexes.push(j);
                    chartVisibility.push(j % 4 < chartsSettings.chartsInRow && isRowVisible);
                }

                rows.push(
                    <ChartRow
                        key={i}
                        indexes={indexes}
                        chartVisibility={chartVisibility}
                        isZoomedIn={false}
                        chartsInRow={chartsSettings.chartsInRow}
                    />
                );
            }

            return (zoomedInIndex === -1 && <div className="charts-container">{rows}</div>);
        } catch (error) {
            dispatch(addLog([`Error rendering chart rows: ${error}`, 2])); // Assuming logType 2 is for errors
        }
    };


    const contextValues = {
        refreshNeeded,
        zoomedInIndex,
        stockApi,
        setErrorMessage,
        containerSizes,
        handleZoomIn,
        windowWidth,
        handleLogCode,
        backToLogin,
        symbolChangedFlag
    };

    const webSocket = {
        ws
    }

    return (<WebSocketContext.Provider value={webSocket}>
        <RefreshContext.Provider value={contextValues}>
            <React.Fragment>
                <ControlMenu handleLogCode={handleLogCode}/>
                <div className={'dashboard-page'}>
                    {renderHeader()}
                    {renderBody()}
                </div>
            </React.Fragment>
        </RefreshContext.Provider>
    </WebSocketContext.Provider>);

}

export default memo(DashboardComponent);