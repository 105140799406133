class MarkingValues {
    static instance = null;

    constructor() {
        if (MarkingValues.instance) {
            return MarkingValues.instance;
        }
        this._markers = {};
        MarkingValues.instance = this;
    }

    get markers() {
        return this._markers;
    }

    set markers(markers) {
        this._markers = markers;
    }

    setMarker(_id, markerData) {
        this._markers[_id] = markerData;
    }

    removeMarker(_id) {
        delete this._markers[_id];
    }


    getMarker(_id) {
        return this._markers[_id] || null;
    }

}

export default MarkingValues;
