// LanguageSelector.js
import React from 'react';
import messages from '../resources/langFile.json';

const LanguageSelector = ({onLocaleChange}) => {
    return (
        <select value={localStorage.getItem('appLocale') || 'en-US'} onChange={(e) => onLocaleChange(e.target.value)}>
            {Object.keys(messages).map((lang, index) => {
                return (<option key={index} value={lang}>{lang}</option>)
            })}

        </select>
    );
};

export default LanguageSelector;
