import React, {useEffect, useMemo, useState} from 'react';
import '../styles/MonitoringServerWindow.css';
import {useRefresh} from "./DashboardComponent";
import {isHebrew, translate} from "./App";
import {useDispatch, useSelector} from "react-redux";
import {toggleShouldLog} from "../redux/dataBankReducer";

const MonitoringServerWindow = ({isMonitoringControlOpen, isMenuOpen}) => {
        // State to track the visibility of each log panel
        const [serverLogNames] = useState(['Debug Logs', 'Warn Logs', 'Error Logs']);
        const [serverLogVisible, setServerLogVisible] = useState([false, false, false,]);
        const [serverLogColor] = useState(['green', 'orange', 'red']);
        const serverLogUpdated = useSelector(state => state.dataBank.shouldLog);
        const dispatch = useDispatch();
        const logs = useSelector(state => state.dataBank.logs);
        const handleLogToggle = (index) => {
            setServerLogVisible([...serverLogVisible.slice(0, index), !serverLogVisible[index], ...serverLogVisible.slice(index + 1, serverLogVisible.length)]);
        }

        function displayLogContentList(logIndex) {
            const logArray = logs[logIndex] || [];
            const color = serverLogColor[logIndex];
            return (
                <ul className="logs-list">
                    {logArray.map((log, index) => (
                        <li key={index} style={{color: color}} className="log-item">
                            {log}
                        </li>
                    ))}
                </ul>
            );
        }


        function handleToggle(checked, index) {
            dispatch(toggleShouldLog([checked, index]));
        }

        const renderServerManagement = () => {
            const subWindowCustomStyle = {
                right: isMonitoringControlOpen && isMenuOpen ? '0' : '-100%',
            overflow: 'auto',
            transition: 'right 0.5s ease-in-out',
            direction: isHebrew() ? 'rtl' : 'ltr',
            };

            return (
                <div className="sub-window" style={subWindowCustomStyle}>
                    <h2>{translate('Monitoring Server')}</h2>
                    <br/>
                    {serverLogVisible.map((isVisible, logIndex) => {
                        return (
                            <div key={logIndex} className="monitoring-server-window">
                                <div className="monitoring-server-window-header">
                                    <h3> {translate(serverLogNames[logIndex])}</h3>
                                    <div className="flipswitch">
                                        <input type="checkbox" name="flipswitch" value={logIndex} className="flipswitch-cb"
                                               id={`fs${logIndex}`}
                                               checked={serverLogUpdated[logIndex]}
                                               onChange={(e) => handleToggle(e.target.checked, logIndex)}/>
                                        <label className="flipswitch-label" htmlFor={`fs${logIndex}`}>
                                            <div className="flipswitch-inner"></div>
                                            <div className="flipswitch-switch"></div>
                                        </label>
                                    </div>
                                </div>
                                <br/>
                                <div className={`${isVisible ? "selected-" : ""}monitor-collapsible-panel`}
                                     onClick={(e) => {
                                         handleLogToggle(logIndex);
                                     }}>
                                    <div className={`${isVisible ? "selected-" : ""}monitor-collapsible-header`}>
                                    <span
                                        className='monitor-header-name'>{translate(serverLogNames[logIndex])} </span>
                                    </div>
                                    {isVisible &&
                                        <div className="collapsible-content logs">
                                            {displayLogContentList(logIndex)}
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            );

        }
        return (
            renderServerManagement()
        );
    }
;

export default MonitoringServerWindow;
