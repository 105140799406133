import React from 'react';
import '../styles/RangeSelector.css';
import {translate} from "./App";
import {useSelector} from "react-redux";

function RangeSelector({index, handleRangeChange}) {

    const range_options = useSelector(state => state.dataBank.rangeOptions);
    const ranges = useSelector(state => state.dataBank.ranges);
    return (<div className="range-selector">
        <label htmlFor="range-select">{translate('Range')}:</label>
        <select id="range-select" value={ranges[index]} onChange={handleRangeChange}>
            {range_options.map((option) => {
                return (
                    <option key={option.value} value={option.value} selected={option.selected}>
                        {translate(option.label)}
                    </option>
                )
            })}
        </select>
    </div>);
}

export default RangeSelector;