// MarkingControlMenu.js
import React, {memo, useState, useEffect, useMemo, useCallback} from 'react';
import '../styles/MakeringControlMenu.css';
import MarkingValues from './MarkingValues';
import StockAPI from '../components/StockApi';
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai';
import CollapsibleList from './CollapsibleList'
import {useNavigate} from 'react-router-dom';
import {isHebrew, translate} from "./App";
import {useDispatch, useSelector} from "react-redux";
import {
    addLog,
    addMarkToReset,
    addNewMarker,
    updateMarkers,
    addChartsDataToReset,
    updateChartsSettings
} from "../redux/dataBankReducer";


const MarkingControlMenu = ({isMarkingControlOpen, isMenuOpen, handleExitSubWindow, handleLogCode}) => {
        const dispatch = useDispatch();
        const [textColor, setTextColor] = useState("green");
        const [message, setMessage] = useState('');
        const navigate = useNavigate();
        const backToLogin = useCallback(() => {
            navigate('/login');
        }, [navigate]);
        const stockApi = useMemo(() => new StockAPI(handleLogCode, backToLogin), [handleLogCode, backToLogin]);
        const markingValues = useMemo(() => new MarkingValues(), []);
        const [changedValues, setChangedValues] = useState({}); // keep all changed values from the marking values before save button click.
        const [isAddMarkSectionOpen, setIsAddMarkSectionOpen] = useState(false);
        const [selectedMark, setSelectedMark] = useState(null);
        const [displayedMark, setDisplayedMark] = useState(null);
        const interval_options = useSelector(state => state.dataBank.intervalOptions);
        const range_options = useSelector(state => state.dataBank.rangeOptions);
        const chartsSettings = useSelector(state => state.dataBank.chartsSettings);
        const currencies = useSelector(state => state.dataBank.currencies);
        const marksToReset = useSelector(state => state.dataBank.chartMarksToReset)
        const [newMark, setNewMark] = useState({
            name: '',
            interval: interval_options[0].value,
            range: range_options[0].value,
            angle_in: -1,
            angle_out: -1,
            normal_range_start: -1,
            normal_range_end: -1,
            min_interval_width: -1,
            max_interval_width: -1,
            action_out: -1,
            action_in: -1
        });
        const [isAddMarkButtonClicked, setIsAddMarkButtonClicked] = useState(false);
        const [isRemoveMarkButtonClicked, setIsRemoveMarkButtonClicked] = useState(false);
        const [numberOfChartSetting, setNumberOfChartSetting] = useState({
            chartsInTotal: chartsSettings.chartsInTotal, chartsInRow: chartsSettings.chartsInRow
        });

        const fetchMarksData = useCallback(async () => {
            try {
                if (markingValues && stockApi) {
                    // Load the marking data from the server.
                    const marksData = await stockApi.get_all_marks();
                    const newMarkers1 = {};
                    const newMarkers2 = {};
                    if (marksData && marksData.length > 0) {
                        marksData.forEach(mark => {
                            newMarkers1[mark._id] = {
                                name: mark.name,
                                interval: mark.interval,
                                range: mark.range,
                                normal_range_start: mark.normal_range_start,
                                normal_range_end: mark.normal_range_end,
                                angle_in: mark.angle_in,
                                angle_out: mark.angle_out,
                                action_in: mark.action_in,
                                action_out: mark.action_out,
                                max_interval_width: mark.max_interval_width,
                                min_interval_width: mark.min_interval_width
                            };
                        });
                        markingValues.markers = newMarkers1;

                        marksData.forEach(mark => {
                            newMarkers2[mark._id] = {
                                name: mark.name,
                                interval: mark.interval,
                                range: mark.range,
                                normal_range_start: mark.normal_range_start,
                                normal_range_end: mark.normal_range_end,
                                angle_in: mark.angle_in,
                                angle_out: mark.angle_out,
                                action_in: mark.action_in,
                                action_out: mark.action_out,
                                max_interval_width: mark.max_interval_width,
                                min_interval_width: mark.min_interval_width
                            };
                        });


                    }
                }
            } catch (error) {
                dispatch(addLog([`got an error in fetchMarksData: ${error}`, 2]));
            }
        }, [stockApi, markingValues]);


        /* Fetch marks useEffect */
        useEffect(() => {
            try {
                fetchMarksData().then(r => dispatch(addLog(['Finished marking fetch', 2])));
            } catch (error) {
                dispatch(addLog([`got an error in Fetch marks useEffect: ${error}`, 2]));
            }
        }, [markingValues, stockApi, fetchMarksData]);


        const checkNewMarkData = async (name, interval, range, min_interval_width, max_interval_width, angle_in, angle_out, normal_range_start, normal_range_end, currencyKeys) => {

            try {
                //checldata validity .
                if (name === '' || interval === '' || range === '' || normal_range_end === -1 || normal_range_start === -1 || min_interval_width === -1 || max_interval_width === -1 || angle_in === -1 || angle_out === -1) {
                    setErrorMessage(translate("Please fill all data for the new mark"));
                    return false;
                }

                if (markingValues.markers.hasOwnProperty(name)) {
                    setErrorMessage(translate("A mark with the same name, interval and range already exists"));
                    return false;
                }
                const response = await stockApi.isStockLegal((name.length === 6 && currencyKeys.includes(name.substring(0, 3)) && currencyKeys.includes(name.substring(3, 6))) ? name + "=X" : name);
                if (response.code !== 200) {
                    if (response.code !== 404) {
                        setErrorMessage(translate("Name is not legal. Please make sure it exists"));
                        return false;
                    } else if (response.code !== 401) {
                        setErrorMessage(translate("Please login again"));
                        return false;
                    }
                }
                return true;
            } catch (error) {
                dispatch(addLog([`got an error in Fetch marks useEffect: ${error}`, 2]));
                return false;
            }
        };

        const handleAddNewMark = async () => {
            try {
                //Fill the range and interval if empty.

                const name = newMark.name;
                const interval = newMark.interval;
                const range = newMark.range;
                const min_interval_width = newMark.min_interval_width;
                const max_interval_width = newMark.max_interval_width;
                const angle_in = newMark.angle_in;
                const angle_out = newMark.angle_out;
                const action_in = newMark.action_in;
                const action_out = newMark.action_out;
                const normal_range_start = newMark.normal_range_start;
                const normal_range_end = newMark.normal_range_end;
                const currencyKeys = Object.values(currencies);


                if (!await checkNewMarkData(name, interval, range, min_interval_width, max_interval_width, angle_in, angle_out, normal_range_start, normal_range_end, currencyKeys)) {
                    return;
                }
                setDebugMessage(translate("Saving mark..."));

                const response = await stockApi.insertMark(name, interval, range, parseInt(min_interval_width), parseInt(max_interval_width), parseFloat(angle_in), parseFloat(angle_out), action_in, action_out, parseFloat(normal_range_start), parseFloat(normal_range_end));
                if (response.code === 200) {
                    //adding the new mark to the marking object.
                    const markerKey = `${name}-${interval}-${range}`;
                    const newMarker = {
                        name: newMark.name,
                        interval: newMark.interval,
                        range: newMark.range,
                        min_interval_width: newMark.min_interval_width,
                        max_interval_width: newMark.max_interval_width,
                        normal_range_start: newMark.normal_range_start,
                        normal_range_end: newMark.normal_range_end,
                        angle_in: newMark.angle_in,
                        angle_out: newMark.angle_out,
                        action_out: action_out,
                        action_in: action_in


                    };
                    // Reset the newMark state to its initial values.
                    setNewMark({
                        name: '',
                        interval: interval_options[0].value,
                        range: range_options[0].value,
                        normal_range_start: -1,
                        normal_range_end: -1,
                        min_interval_width: -1,
                        max_interval_width: -1,
                        angle_in: -1,
                        angle_out: -1,
                        action_in: -1,
                        action_out: -1
                    });
                    debugger
                    dispatch(addMarkToReset(markerKey));
                    dispatch(addNewMarker([markerKey, newMarker]))
                    setMessage('');
                    setTimeout(() => setIsAddMarkSectionOpen(false), 200);
                    dispatch(addLog([`New mark ${markerKey} was added`, 0]));
                }
            } catch (error) {
                dispatch(addLog([`Error adding new mark. Error: ${error}`, 2]));
                setErrorMessage(translate("Could not add mark. Please try again later"));
            }

        };

        const setErrorMessage = (message) => {
            setTextColor("red");
            setMessage(message);
            setTimeout(() => {
                setMessage("");
                setTextColor("green");
            }, 3000);
        };

        const setDebugMessage = (message) => {
            setTextColor("green");
            setMessage(message);
            setTimeout(() => {
                setMessage("");
            }, 3000);
        };

        const FitMessage = (code) => {
            if (code === 200) {
                setDebugMessage(translate('Action finished successfully'));
            } else {
                setErrorMessage(translate('Action failed. Please try again or contact support'))
            }
        }
        const handleRemoveMark = async () => {

            try {
                setDebugMessage(translate("Removing mark..."));
                setIsRemoveMarkButtonClicked(true);
                setTimeout(() => setIsRemoveMarkButtonClicked(false), 200);
                if (selectedMark !== null) {
                    try {
                        //get the mark by id .
                        const mark = markingValues._markers[selectedMark];
                        const response = await stockApi.deleteMark(mark.name, mark.interval, mark.range);
                        if (response.code === 200) {
                            //remove the mark from the list.
                            FitMessage(response.code);
                            setTimeout(() => setIsAddMarkSectionOpen(false), 200);
                            markingValues.removeMarker(selectedMark);
                            setSelectedMark(null);
                            setDisplayedMark(null);
                        } else {
                            setErrorMessage(translate(`Could not remove mark due to server error`));

                        }
                    } catch (error) {
                        dispatch(addLog([`Error while removing mark:${error}`, 2]));
                        setErrorMessage(translate("Could not remove mark. Please try again later"));
                    }
                } else {
                    setErrorMessage(translate("Please select a mark first"));
                }
            } catch (error) {
                dispatch(addLog([`Got an error in handleRemoveMark: :${error}`, 2]));
            }
        };

        const isFloat = (str) => {
            return !isNaN(parseFloat(str))
        };

        const isInt = (str) => {
            return !isNaN(parseInt(str))
        };

        const isString = (value) => {
            return typeof value === 'string'
        };

        const handleAddMark = () => {
            setIsAddMarkButtonClicked(true);
            setTimeout(() => setIsAddMarkButtonClicked(false), 100);
            setIsAddMarkSectionOpen(!isAddMarkSectionOpen);
        };

        const handleMarkClick = (mark_id) => {

            if (selectedMark === mark_id) {
                setSelectedMark(null);

            } else {
                setSelectedMark(mark_id);

            }
        };
        const handleMarkDoubleClick = (mark_id) => {

            if (displayedMark === mark_id) {
                setDisplayedMark(null);

            } else {
                setDisplayedMark(mark_id);

            }
        };

        function handleValueMarkSet(_id, field, value) {
            try {
                // Get the marker from the updated markers dictionary or create a new one based on the default marker
                const defaultMarker = changedValues[_id] || markingValues.getMarker(_id);
                // copuing the object for change.
                const updatedMarker = {
                    name: defaultMarker.name,
                    interval: defaultMarker.interval,
                    range: defaultMarker.range,
                    max_interval_width: defaultMarker.max_interval_width,
                    min_interval_width: defaultMarker.min_interval_width,
                    angle_in: defaultMarker.angle_in,
                    angle_out: defaultMarker.angle_out,
                    action_in: defaultMarker.action_in,
                    action_out: defaultMarker.action_out,
                    normal_range_start: defaultMarker.normal_range_start,
                    normal_range_end: defaultMarker.normal_range_end
                };
                // Update the markers in the updatedMarker instance.
                updatedMarker[field] = value;

                // Updating the changed object for future saving.
                setChangedValues(prevState => ({
                    ...prevState, [_id]: updatedMarker
                }));

            } catch (error) {
                dispatch(addLog([`Got an error in handleValueMarkSet: ${error}`, 2]));
                return false;
            }
        }

        const check_values_before_update = () => {

            try {
                Object.entries(changedValues).forEach(([key, mark]) => {
                    Object.entries(mark).forEach(([field, value]) => {
                        if (field === 'normal_range_start' || field === 'normal_range_end' || field === 'angle_in' || field === 'angle_out') {
                            if (!isString(value) || !isFloat(value)) {
                                setErrorMessage(`${translate(`Values aren't legal on `)} \n${field} ${value} ${translate('is not a float')}`);
                                return false;
                            } else {
                                changedValues[key][field] = parseFloat(value);
                            }
                        } else if (field === 'max_interval_width' || field === 'min_interval_width') {
                            if (!isString(value) || !isInt(value)) {
                                setErrorMessage(`${translate(`Values aren't legal on `)} \n${field}. ${value} ${translate('is not a integer')}`);
                                return false;
                            } else {
                                changedValues[key][field] = parseFloat(value);
                            }
                            changedValues[key][field] = parseInt(value);
                        }
                    });
                });
                return true;
            } catch (error) {
                dispatch(addLog([`Got an error in check_values_before_update: ${error}`, 2]));
                return false;
            }
        };

        const handleSaveMarksChanges = async () => {
            try {
                //Iterate over each change value, and update it on server.
                // Save changes in server using stockAPI.
                if (!check_values_before_update()) {
                    return;
                }
                setDebugMessage(translate("updating change values..."));
                const response = await stockApi.updateAllMarksInOneRequest(changedValues);
                if (response.code === 200) {
                    Object.entries(changedValues).forEach(([_id, mark_obj]) => {
                        markingValues._markers[_id] = {
                            name: mark_obj.name,
                            interval: mark_obj.interval,
                            range: mark_obj.range,
                            max_interval_width: mark_obj.max_interval_width,
                            min_interval_width: mark_obj.min_interval_width,
                            angle_in: mark_obj.angle_in,
                            angle_out: mark_obj.angle_out,
                            action_in: mark_obj.action_in,
                            action_out: mark_obj.action_out,
                            normal_range_start: mark_obj.normal_range_start,
                            normal_range_end: mark_obj.normal_range_end
                        }
                    });
                    //Displaying status and reset the updated values.
                    setChangedValues({});
                    FitMessage(response.code);
                    setTimeout(() => setIsAddMarkSectionOpen(false), 200);
                    dispatch(addChartsDataToReset([...Object.keys(changedValues)]));
                    dispatch(updateMarkers([...Object.keys(changedValues), ...Object.values(changedValues)]))
                    dispatch(addLog([`New marks ${Object.keys(changedValues)} was saved`, 0]));
                    handleExitWindow();
                } else {
                    dispatch(addLog([`Could not save marks ${Object.keys(changedValues).toString()} due to server error.`, 1]));
                    setErrorMessage(translate(`Could not save marks due to server error.`));
                }

            } catch (error) {
                dispatch(addLog([`Error updating marks values  ${Object.keys(changedValues).toString()}. Error: ${error}`, 2]));
                setErrorMessage(translate("Could not update values. Please try again later"));
            }
        };

        const handleCancelChanges = () => {
            // Reset the changedValues state to an empty object
            setChangedValues({});

            // Close the add mark section if it's open
            setIsAddMarkSectionOpen(false);

            // Reset the newMark state to its initial values
            setNewMark({
                name: '',
                interval: interval_options[0].value,
                range: range_options[0].value || '',
                angle_in: -1,
                angle_out: -1,
                action_in: -1,
                action_out: -1,
                normal_range_start: -1,
                normal_range_end: -1,
                min_interval_width: -1,
                max_interval_width: -1
            });

            // Reset the selected mark
            setSelectedMark(null);
            setDisplayedMark(null);

        };

        const handleExitWindow = () => {

            // Reset all marking control states.
            setChangedValues({});
            setIsAddMarkSectionOpen(false);
            setSelectedMark(null);
            setDisplayedMark(null);
            setNewMark({
                name: '',
                interval: interval_options[0].value || '',
                range: range_options[0].value || '',
                angle_in: -1,
                angle_out: -1,
                action_in: -1,
                action_out: -1,
                normal_range_start: -1,
                normal_range_end: -1,
                min_interval_width: -1,
                max_interval_width: -1
            });
            setIsRemoveMarkButtonClicked(false);
            setIsAddMarkButtonClicked(false);
            handleExitSubWindow();
            setNumberOfChartSetting({
                chartsInTotal: chartsSettings.chartsInTotal.chartsInTotal,
                chartsInRow: chartsSettings.chartsInRow
            });
        };


        const handleNewMarkChange = (event) => {
            let {name, value} = event.target;
            // Update the newMark state based on the input name and value
            if (name === 'min_interval_width' || name === 'max_interval_width') {
                value = Number(value).toFixed();
            }
            setNewMark((prevState) => ({
                ...prevState, [name]: value
            }));
        };

        const handleChartSetChange = (event) => {
            const {name, value} = event.target;
            setNumberOfChartSetting((prevState) => ({
                ...prevState, [name]: value
            }));
        };

        function handleChartsNumber(inTotal, inRow) {
            dispatch(updateChartsSettings([inTotal, inRow]));
            stockApi.updateUserPreferences('ChartCount', 'total$in_row', inTotal + '$' + inRow)
                .then(_ => {
                    dispatch(addLog([`Updated charts display preferences of chartsInTotal to ${inTotal} and chartsInRow to ${inRow} successfully`, 0]));
                }).catch(error => {
                dispatch(addLog([`Failed to update charts display preferences to ${inRow}: ${error}`, 2]));
            });
        }

        const handleSaveChartPreferences = () => {

            if (!isInt(numberOfChartSetting.chartsInTotal) || !isInt(numberOfChartSetting.chartsInRow)) {
                setErrorMessage(translate("Set the values to legal numbers"));
                return;
            }
            setDebugMessage(translate("Saving changes..."));
            handleChartsNumber(parseInt(numberOfChartSetting.chartsInTotal), parseInt(numberOfChartSetting.chartsInRow));
        };

        const renderCharsSetup = () => {
            return (<div style={{display: "flex", flexDirection: 'column'}}>
                <h2>{translate('Charts preferences')}</h2>
                <div className='charts-preferance-container'>
                    <div className="charts-setting-input-container">
                        <label>{translate('Number of graphs')}:</label>
                        <input className="input-line" type="number" value={numberOfChartSetting.chartsInTotal} min="1"
                               max="16" name="chartsInTotal" onChange={handleChartSetChange}/>
                    </div>
                    <div className='ten-vertical-space'></div>
                    <div className="charts-setting-input-container">
                        <label>{translate('Number of graphs in each row')}:</label>
                        <input className="input-line" type="number" value={numberOfChartSetting.chartsInRow} min="1"
                               max="4" name="chartsInRow" onChange={handleChartSetChange}/>
                    </div>
                </div>
                <div className="fifty-vertical-space"></div>
                <button className="mark-button"
                        onClick={handleSaveChartPreferences}>{translate('Save Changes')}</button>
                <hr/>
            </div>);
        };

        const renderMarkTableSection = () => {
            const items = Object.entries(markingValues.markers).map(([key, defaultMarker]) => {
                const marker = changedValues[key] || defaultMarker;

                return {
                    name: marker.name,
                    interval: marker.interval,
                    range: marker.range,
                    normal_range_start: marker.normal_range_start,
                    normal_range_end: marker.normal_range_end,
                    angle_in: marker.angle_in,
                    angle_out: marker.angle_out,
                    action_in: marker.action_in,
                    action_out: marker.action_out,
                    max_interval_width: marker.max_interval_width,
                    min_interval_width: marker.min_interval_width,
                };
            });


            if (items.length === 0) {
                return (<div className="user-list-container">
                    <div className="message-box" style={{color: textColor}}>{message}</div>
                </div>);
            }
            // Specify which fields can be modified
            const modifiable = items.map(() => ({
                name: false,
                interval: false,
                range: false,
                normal_range_start: true,
                normal_range_end: true,
                angle_in: true,
                angle_out: true,
                action_in: true,
                action_out: true,
                max_interval_width: true,
                min_interval_width: true,
            }));

            // If any other handlers are required, you can add them here.

            const handlers = Object.keys(modifiable[0]).reduce((acc, key) => {
                if (modifiable[0][key] && !acc[key]) {
                    acc[key] = function (id, value) {
                        handleValueMarkSet(id, key, value);
                    };
                }
                return acc;
            }, {});


            return (<div>
                <h2>{translate('Marking Control')}</h2>
                <div className="user-list-container">
                    <CollapsibleList
                        items={items}
                        handlers={handlers}
                        modifiable={modifiable}
                        handleMarkClick={handleMarkClick}
                        handleMarkDoubleClick={handleMarkDoubleClick}
                        selectedMark={selectedMark}
                        displayedMark={displayedMark}
                    />
                </div>
            </div>);

        };

        const renderAddMarkerSection = () => {
            if (!isAddMarkSectionOpen) {
                return null;
            }
            return (
                <div className="user-edit-container">
                    <div className='name-container'>
                        <div className="input-container">
                            <label>-{translate('Symbol Name')}:</label>
                            <input className="input-line" type="text" name="name" onChange={handleNewMarkChange}/>
                        </div>
                        <label
                            className='name-message'>({translate('Put name for stocks and pair with \'XXXYYY\' for currencies')})</label>
                    </div>
                    <div className="input-container">
                        <label>-{translate('Interval')}:</label>
                        <select className="input-line" name="interval" onChange={handleNewMarkChange}>
                            {interval_options.map((option) => (
                                <option key={option.value} value={option.value} selected={option.selected}>
                                    {option.label}
                                </option>))}
                        </select>
                    </div>
                    <div className="input-container">
                        <label>-{translate('Range')}:</label>
                        <select className="input-line" name="range" onChange={handleNewMarkChange}>
                            {range_options.map((option) => (
                                <option key={option.value} value={option.value} selected={option.selected}>
                                    {option.label}
                                </option>))}
                        </select>
                    </div>
                    <div className='two-val-container'>
                        <div className="input-container">
                            <label>-{translate('Normal Range Start')}:</label>
                            <input className="input-line" name="normal_range_start" type={'number'}
                                   onChange={handleNewMarkChange}/>
                        </div>
                        <div className="input-container">
                            <label>-{translate('Normal Range End')}:</label>
                            <input className="input-line" name="normal_range_end" type={'number'}
                                   onChange={handleNewMarkChange}/>
                        </div>
                    </div>
                    <div className='two-val-container'>
                        <div className="input-container">
                            <label>-{translate('Min Interval Width')}:</label>
                            <input className="input-line" name="max_interval_width" type={'number'}
                                   onChange={handleNewMarkChange}/>
                        </div>
                        <div className="input-container">
                            <label>-{translate('Max Interval Width')}:</label>
                            <input className="input-line" name="min_interval_width" type={'number'}
                                   onChange={handleNewMarkChange}/>
                        </div>
                    </div>
                    <div className="input-container-angle">
                        <label>-{translate('Angle in')}:</label>
                        <input className="input-line angle" name="angle_in" type={'number'}
                               onChange={handleNewMarkChange}/>
                        <select className="input-line angle select" name="action_in" onChange={handleNewMarkChange}>
                            <option value="gt">{translate('gt')}</option>
                            <option value="gte">{translate('gte')}</option>
                            <option value="eq">{translate('eq')}</option>
                            <option value="lte">{translate('lte')}</option>
                            <option value="lt">{translate('lt')}</option>
                        </select>

                    </div>
                    <div className="input-container-angle">
                        <label>-{translate('Angle out')}:</label>
                        <input className="input-line angle " name="angle_out" type={'number'}
                               onChange={handleNewMarkChange}/>
                        <select className="input-line angle select" name="action_out" onChange={handleNewMarkChange}>
                            <option value="gt">{translate('gt')}</option>
                            <option value="gte">{translate('gte')}</option>
                            <option value="eq">{translate('eq')}</option>
                            <option value="lte">{translate('lte')}</option>
                            <option value="lt">{translate('lt')}</option>
                        </select>

                    </div>
                    <div className="buttons-container">
                        <button className="mark-button" onClick={handleAddNewMark}>{translate('Save Mark')}</button>
                        <button className="mark-button" onClick={handleCancelChanges}>{translate('Cancel')}</button>
                    </div>
                </div>)
        };

        function renderMarkerManagement() {
            const subWindowCustomStyle = {
                right: isMarkingControlOpen && isMenuOpen ? '0' : '-100%',
                overflow: 'auto',
                transition: 'right 0.5s ease-in-out',
                direction: isHebrew() ? 'rtl' : 'ltr',
            };

            return (<div className="sub-window" style={subWindowCustomStyle}>
                {renderCharsSetup()}
                {renderMarkTableSection()}
                {renderAddMarkerSection()}
                <div className="button-container menu">
                    {!isAddMarkSectionOpen && (<div className="add-user-container">
                        <AiOutlinePlus size={24}
                                       className={isAddMarkButtonClicked ? "add-icon-clicked" : "add-icon"}
                                       onClick={handleAddMark}/>
                        <AiOutlineMinus size={24}
                                        className={isRemoveMarkButtonClicked ? "remove-icon-clicked" : "remove-icon"}
                                        onClick={handleRemoveMark}/>
                    </div>)}
                </div>
                <div className="message-box" style={{color: textColor}}>{message}</div>

                <div className="buttons-container-window">
                    <button className="mark-button" onClick={handleExitWindow}>{translate('Exit')}</button>
                    <button className="mark-button" onClick={handleSaveMarksChanges}>{translate('Save Changes')}</button>
                </div>
                <hr/>
                <div className="fifty-vertical-space"></div>

            </div>);
        }


        return (renderMarkerManagement());
    }
;

export default memo(MarkingControlMenu);
