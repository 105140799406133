// src/reducers/dataBankReducer.js
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    loaded: false,
    logs: [[], [], []], // 0: debug, 1: warn, 2: error
    shouldLog: [true, true, true],
    intervalOptions: [
            {value: "1", label: "1 Minute"},
            {value: "2", label: "2 Minutes"},
            {value: "5", label: "5 Minutes"},
            {value: "15", label: "15 Minutes"},
            {value: "30", label: "30 Minutes"},
            {value: "60", label: "60 Minutes"},
            {value: "90", label: "90 Minutes"},
            {value: "1D", label: "1 Day"},
            {value: "1W", label: "1 Week"},
            {value: "1M", label: "1 Month"},
            {value: "3M", label: "3 Months"},
        ],
    rangeOptions: [
            {value: "1D", label: "1 Day"},
            {value: "1M", label: "1 Month"},
            {value: "3M", label: "3 Months"},
            {value: "6M", label: "6 Months"},
            {value: "12M", label: "1 Year"},
            {value: "24M", label: "2 Years"},
            {value: "60M", label: "5 Years"},
            {value: "120M", label: "10 Years"},
            {value: "max", label: "All Time"}
        ],
    chartsSettings: {chartsInTotal: 6, chartsInRow: 3},
    chartMarksToReset: [],
    chartsData: {},
    roles: {},
    currencies: [],
    stocks: [],
    intervals: {},
    ranges: {},
    stockName: '',
    markers: {},
    displayMode: 'currency' // 'stock' or 'currency'
};

export const dataBankSlice = createSlice({
    name: 'dataBank', initialState,
    reducers: {
        setLoaded: (state, action) => {
            state.loaded = action.payload;
        },
        loadData: (state, action) => {
            const roles = action.payload[0];
            const currencies = action.payload[1];
            const initGraphs = action.payload[2];
            const stocks = action.payload[3];
            state.roles = Object.values(roles).reduce((acc, curr) => {
                acc[curr.name] = curr.level;
                return acc;
            }, {});
            state.currencies = currencies;
            state.stocks = stocks;
            state.intervals = {};
            state.ranges = {};
            state.chartsSettings = {};
            state.stockName = '';

            for (let [id, values] of Object.entries(initGraphs)) {
                if (isNaN(parseInt(id))) {
                    state.chartsSettings = {
                        chartsInTotal: values.total,
                        chartsInRow: values.in_row,
                    };
                } else {
                    id = parseInt(id);
                    if (id !== -1) {
                        state.intervals[id] = values.interval;
                        state.ranges[id] = values.range;
                    } else {
                        state.stockName = values.currency;
                    }
                }
            }
        },
        addMarkToReset: (state, action) => {
            state.chartMarksToReset.push(action.payload);
        },
        removeMarkFromReset: (state, action) => {
            state.chartMarksToReset = state.chartMarksToReset.filter(mark => mark !== action.payload);
        },
        toggleShouldLog: (state, action) => {
            const {value, index} = action.payload;
            state.shouldLog[index] = value;
        },
        addLog: (state, action) => {
            const log = action.payload[0];
            const logIndex = action.payload[1];
            state.logs[logIndex].push(log);
        },
        loadMarkers: (state, action) => {
            state.markers = action.payload;
        },
        updateChartsSettings: (state, action) => {
            debugger
            const chartsInTotal = action.payload[0];
            const chartsInRow = action.payload[1];
            state.chartsSettings = {chartsInTotal, chartsInRow};
        },
        updateCurrencies: (state, action) => {
            state.currencies = action.payload;
        },
        updateStocks: (state, action) => {
            state.stocks = action.payload;
        },
        updateIntervals: (state, action) => {
            state.intervals = action.payload;
        },
        setNewInterval: (state, action) => {
            debugger
            const index = action.payload[0];
            const value = action.payload[1];
            state.intervals[index] = value;
        },
        setNewRange: (state, action) => {
            const index = action.payload[0];
            const value = action.payload[1];
            state.ranges[index] = value;
        },
        updateRanges: (state, action) => {
            state.ranges = action.payload;
        },
        setStockName: (state, action) => {
            state.stockName = action.payload;
        },
        setChartsDataToReset: (state, action) => {
            state.chartMarksToReset = action.payload;
        },
        addChartsDataToReset: (state, action) => {
            state.chartMarksToReset = [...state.chartMarksToReset, ...action.payload];
        },
        addNewMarker :(state, action) =>{
          state.markers[action[0]] = action[1];
        },
        updateMarkers: (state, action) => {
            const markersKey = action.payload[0];
            const marksValues = action.payload[0];
            const updated = state.markers;
            updated[markersKey] = marksValues;
            state.markers = updated;
        },
        setDisplayMode: (state, action) => {
            state.displayMode = action.payload;
        },
        removeLogEntry: (state, action) => {
            state.logs[action.payload] = [];
        },
        clearLogs: (state, action) => {
            const logType = action.payload;
            if (logType !== undefined && state.logs.length > logType) {
                state.logs[logType] = [];
            } else {
                state.logs = state.logs.map(() => []);
            }
        },
        setChartsData: (state, action) => {
            state.chartsData = {
                ...state,
                [action.payload[0]]: action.payload[1]
            };
        }
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

// Export the action creators
export const {
    setLoaded,
    updateRanges,
    loadData,
    updateIntervals,
    removeMarkFromReset,
    addMarkToReset,
    toggleShouldLog,
    updateStocks,
    addLog,
    loadMarkers,
    updateChartsSettings,
    setStockName,
    toggleSpecificLog,
    removeLogEntry,
    clearLogs,
    updateCurrencies,
    updateMarkers,
    setDisplayMode,
    setNewInterval,
    setNewRange,
    setChartsDataToReset,
    addNewMarker,
    addChartsDataToReset,
    setChartsData
} = dataBankSlice.actions;

// Export the reducer
export default dataBankSlice.reducer;
