// UserControlMenu.js
import React, {memo, useState, useEffect, useCallback, useMemo} from 'react';
import '../styles/UserControlMenu.css';
import StockAPI from './StockApi';
import {AiOutlinePlus, AiOutlineMinus} from 'react-icons/ai';
import {MdEdit} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';
import TableComponent from "./TableComponent";
import {isHebrew, translate} from "./App";
import {useDispatch, useSelector} from "react-redux";
import {addLog} from "../redux/dataBankReducer";


const UserControlMenu = ({
                             isUserControlOpen,
                             isMenuOpen,
                             handleExitSubWindow,
                             handleLogCode
                         }) => {

    const navigate = useNavigate();
    const backToLogin = useCallback(() => {
        navigate('/login');
    }, [navigate]);
    const stockApi = useMemo(() => new StockAPI(handleLogCode, backToLogin), [handleLogCode, backToLogin]);
    const dispatch = useDispatch();
    const roles = useSelector(state => state.dataBank.roles);
    const [textColor, setTextColor] = useState("green");
    const [message, setMessage] = useState('');
    const last_role_key = Object.keys(roles)[Object.keys(roles).length - 1];
    const [newUser, setNewUser] = useState({userName: '', password: '', email: '', salt: '', authName: last_role_key});
    const [editedUser, setEditedUser] = useState({});
    const [usersData, setUsersData] = useState([]);
    const [requestsData, setRequestsData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [isAddUserSectionOpen, setIsAddUserSectionOpen] = useState(false);
    const [isEditSelected, setIsEditSelected] = useState(false);
    const [isAddUserButtonClicked, setIsAddUserButtonClicked] = useState(false);
    const [isRemoveUserButtonClicked, setIsRemoveUserButtonClicked] = useState(false);
    const [isEditUserButtonClicked, setIsEditUserButtonClicked] = useState(false);
    const requestsColumns = ['email', 'firstName', 'lastName', 'phoneNumber'];
    const userColumns = ['email', 'userName', 'salt', 'authName'];

    const fetchUsersData = useCallback(async () => {
        try {
            const data = await stockApi.fetchUsersData();

            //Get the list of all values from the data dictionary.
            const values = Object.values(data);
            setUsersData(values);
        } catch (error) {
            dispatch(addLog([`Error fetching users data: ${error}`, 2]));
            setErrorMessage(`There was an error loading users list. Please try again later.`);
        }
    }, [stockApi]);


    const fetchRequestsData = useCallback(async () => {
        try {
            const data = await stockApi.fetchRequestsData();

            //Get the list of all values from the data dictionary.
            const values = Object.values(data);
            setRequestsData(values);
        } catch (error) {
            dispatch(addLog([`Error fetching requests data: ${error}`, 2]));
        }
    }, [stockApi]);


    useEffect(() => {
        fetchUsersData().then(_ => dispatch(addLog(['Finished loading users data', 0]))).catch(error => dispatch(addLog([`Failed loading users data. Error: ${error}`, 2])));
        fetchRequestsData().then(_ => dispatch(addLog(['Finished loading requests data']))).catch(error => dispatch(addLog([`Failed loading requests data. Error: ${error}`, 2])));
    }, [isUserControlOpen, fetchUsersData, fetchRequestsData]);


    const handleUserClick = (user) => {
        if (selectedUser === user) {
            setSelectedUser(null);  // Unselect the user if it's already selected
            setEditedUser({});  // Also clear any edits
        } else {
            setSelectedUser(user);  // Otherwise select the new user
            setEditedUser({...user});  // And prepare a copy for editing
        }
    };

    const handleRequestClick = (user) => {
        if (selectedRequest === user) {
            setSelectedRequest(null);  // Unselect the user if it's already selected
        } else {
            setSelectedRequest(user);  // Otherwise select the new user
        }
    };

    const handleAddUser = () => {
        setIsAddUserButtonClicked(true);
        setTimeout(() => setIsAddUserButtonClicked(false), 100);
        setIsAddUserSectionOpen(!isAddUserSectionOpen);

    };

    const handleEditUser = () => {
        if (selectedUser) {
            if (isEditSelected) {
                setIsEditSelected(false);
            } else {
                setIsEditSelected(true);
                setIsEditUserButtonClicked(true);
                setTimeout(() => {
                    setIsEditUserButtonClicked(false);
                }, 100);
            }
        } else {
            setErrorMessage(translate("Please select a user first"));
        }
    };

    const handleCancelEdit = () => {
        setIsEditSelected(false);
    };

    const handleCancelAdd = () => {
        setIsAddUserSectionOpen(!isAddUserSectionOpen);
    };

    const handleRemoveUser = async () => {

        setTextColor("green");
        setMessage("Removing user...");
        setIsRemoveUserButtonClicked(true);
        setTimeout(() => setIsRemoveUserButtonClicked(false), 200);
        if (selectedUser != null) {
            try {
                const response = await stockApi.removeUser(selectedUser);
                if (response.code === 200) {
                    //Remove the user.
                    setUsersData(prevUsers => prevUsers.filter((user) => user.username !== selectedUser.username));
                    const last_role_key = Object.keys(roles)[Object.keys(roles).length - 1];
                    setNewUser({userName: '', password: '', email: '', salt: '', authName: last_role_key}); // clear the input fields
                    FitMessage(response.code);
                    setTimeout(() => setIsAddUserSectionOpen(false), 200);
                    setSelectedUser(null);
                } else {
                    setErrorMessage(translate(`Could not remove user due to server error.`))


                }
            } catch (error) {
                dispatch(addLog([`Error while removing user: ${error}`, 2]));
                setErrorMessage(translate("Could not remove user. Please try again later"))

            }
        } else {
            setErrorMessage(translate("Please select a user first"));
        }
    };

    const handleNewUserChange = (event) => {
        const {name, value} = event.target;
        setNewUser((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleAddNewUser = async () => {
        try {
            setTextColor("green");
            setMessage("Saving user...");
            const response = await stockApi.addUser(newUser);
            if (response.code === 200) {
                const last_role_key = Object.keys(roles)[Object.keys(roles).length - 1];
                FitMessage(response.code);
                setTimeout(() => setIsAddUserSectionOpen(false), 200); // close the window of new user.
                setUsersData(prevUsers => [...prevUsers, newUser]); //Insert the new user to the list.
                setNewUser({userName: '', password: '', email: '', salt: '', authName: last_role_key}); // clear the input fields
            } else {
                dispatch(addLog(['Could not add user due to server error.', 2]));
                setErrorMessage(translate(`Could not add user due to server error.`));
            }
        } catch (error) {
            dispatch(addLog([`Error adding new user: ${error}`, 2]));
            setErrorMessage(translate("Could not add user. Please try again later"));
        }
    };

    const handleEditedUserChange = (event) => {
        const {name, value} = event.target;
        setEditedUser((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const FitMessage = (code) => {
        if (code === 200) {
            setDebugMessage(translate('Action finished successfully'));
        } else {
            setErrorMessage(translate('Action failed. Please try again or contact support'))
        }
    }
    const handleUpdateUser = async () => {
        setDebugMessage("updating user...");

        try {
            const updatedFields = Object.keys(editedUser).reduce((result, key) => {
                if (editedUser[key] !== selectedUser[key] || key === "username") {
                    result[key] = editedUser[key];
                } else {
                    result[key] = "";
                }
                return result;
            }, {});

            // Call the update user function on your API and pass in the editedUser object
            const response = await stockApi.updateUser(updatedFields);
            if (response.code === 200) {
                setUsersData(prevUsers => {
                    return prevUsers.map(user =>
                        user.username === selectedUser.username ? editedUser : user
                    );
                }); // updating the user in the list.
                setSelectedUser(null); // clear the selected user.
                setEditedUser({}); // clear the edited user.
                setIsEditSelected(false);
                FitMessage(response.code);
            } else {
                dispatch(addLog([`Could not update user due to server error`, 2]));
                setErrorMessage(translate(`Could not update user due to server error`));
            }
        } catch (error) {
            dispatch(addLog([`Error updating user: ${error}`, 2]));
            setErrorMessage(translate("Could not update user. Please try again later"));
        }
    };

    const handleApproveRequest = async () => {
        if (selectedRequest != null) {
            try {
                const response = await stockApi.approveRequest(selectedRequest.email);
                if (response.code === 200) {
                    await fetchRequestsData(); // re-fetch user data to include the new user
                    await fetchUsersData();
                    FitMessage(response.code);
                    dispatch(addLog([`Request of ${selectedRequest.email} was approved`, 2]));
                } else {
                    setErrorMessage(translate(`Could not approve request due to server error`));
                }
            } catch (error) {
                dispatch(addLog([`Error while approving request: ${error}`, 2]));
                setErrorMessage(translate("Could not approve request. Please try again later"));
            }
        } else {
            setErrorMessage(translate("Please select a request first"));
        }
    };

    const handleRejectRequest = async () => {
        if (selectedRequest != null) {
            try {
                const response = await stockApi.rejectRequest(selectedRequest.email);
                if (response.code === 200) {
                    await fetchRequestsData(); // re-fetch user data to include the new user
                    FitMessage(response.code);
                    dispatch(addLog([`Request of ${selectedRequest.email} was rejected`, 2]));
                } else {
                    dispatch(addLog(['Could not reject request due to server error.', 2]));
                    setErrorMessage(translate(`Could not reject request due to server error`));
                }
            } catch (error) {
                dispatch(addLog([`Error while rejecting request: ${error}`, 2]));
                setErrorMessage(translate("Could not reject request. Please try again later"));
            }
        } else {
            dispatch(addLog(['Please select a request first', 2]));
            setErrorMessage(translate("Please select a request first"));
        }
    };

    const handleExitWindow = () => {

        //reset user control menu states.
        setSelectedUser(null);
        setIsAddUserSectionOpen(false);
        setIsEditSelected(false);
        setIsAddUserButtonClicked(false);
        setIsRemoveUserButtonClicked(false);
        setIsEditUserButtonClicked(false);
        const last_role_key = Object.keys(roles)[Object.keys(roles).length - 1];
        setNewUser({userName: '', password: '', email: '', salt: '', authName: last_role_key});
        setEditedUser({});
        setUsersData([]);
        setRequestsData([]);
        handleExitSubWindow();
    };

    const setErrorMessage = (message) => {
        setTextColor("red");
        setMessage(message);
        setTimeout(() => {
            setMessage("");
            setTextColor("green");
        }, 3000);
    };

    const setDebugMessage = (message) => {
        setTextColor("green");
        setMessage(message);
        setTimeout(() => {
            setMessage("");
        }, 3000);
    };

    const renderAccessRequestSection = () => {


        return (
            <div>
                <h2>{translate("Access Requests")}</h2>
                <div className="user-list-container">
                    <TableComponent columns={requestsColumns} data={requestsData} selectedRow={selectedRequest}
                                    handleRequestClick={handleRequestClick}/>
                    <div className="vertical-space"></div>
                    <div className={'buttons-container'}>
                        <button className="user-button" onClick={handleApproveRequest}>{translate("Approve")}</button>
                        <button className="user-button" onClick={handleRejectRequest}> {translate("Reject")}</button>
                    </div>
                </div>
            </div>
        );


    };

    const renderUserTableSection = () => {

        return (
            <div>
                <h2>{translate("Users Management")}</h2>
                <div className="user-list-container">
                    <TableComponent columns={userColumns} data={usersData} selectedRow={selectedUser}
                                    handleRequestClick={handleUserClick}/>
                </div>
            </div>
        );
    };

    const renderEditUserSection = () => {

        if (!selectedUser || !isEditSelected) {
            return null;
        }
        return (
            <div className="user-edit-container">
                <div className="input-container">
                    <label>{translate("Username(read only)")}:</label>
                    <input className="input-line" type="text" name="username" value={editedUser.username} readOnly/>
                </div>
                <div className="input-container">
                    <label>{translate("Salt")}:</label>
                    <input className="input-line" type="text" name="salt" value={editedUser.salt}
                           onChange={handleEditedUserChange}/>
                </div>
                <div className="input-container">
                    <label>{translate("Role")}:</label>
                    <select name="authName" value={editedUser.authName} onChange={handleEditedUserChange}>
                        {Object.keys(roles).map(role => (
                            <option value={role}>{role}</option>
                        ))}
                    </select>
                </div>
                <button className="user-button" onClick={handleUpdateUser}> {translate("Update")}</button>
                <button className="user-button" onClick={handleCancelEdit}> {translate("Cancel")}</button>
            </div>
        )
    };


    const renderAddUserSection = () => {
        if (!isAddUserSectionOpen) {
            return null;
        }
        return (
            <div className="user-edit-container">
                <div className="input-container">
                    <label>{translate("Email")}:</label>
                    <input className="input-line" type="text" name="email" onChange={handleNewUserChange}/>
                </div>
                <div className="input-container">
                    <label>{translate("Username")}:</label>
                    <input className="input-line" type="text" name="username" onChange={handleNewUserChange}/>
                </div>
                <div className="input-container">
                    <label>{translate("Password")}:</label>
                    <input className="input-line" type="password" name="password" onChange={handleNewUserChange}/>
                </div>
                <div className='salt-container'>
                    <div className="input-container">
                        <label>{translate("Salt")}:</label>
                        <input className="input-line" type="text" name="salt" onChange={handleNewUserChange}/>
                    </div>
                    <label
                        className='salt-message'>({translate('Set for password hashing or leave empty for default')})</label>
                </div>
                <div className="input-container">
                    <label>{translate("Role")}:</label>
                    <select name="authName" onChange={handleNewUserChange}>
                        {Object.keys(roles).map(role => (
                            <option value={role}>{translate(role)}</option>
                        ))}
                    </select>
                </div>
                <br/>
                <button className="user-button" onClick={handleAddNewUser}>{translate("Save User")}</button>
                <button className="user-button" onClick={handleCancelAdd}> {translate("Cancel")}</button>
            </div>
        )
    };

    function renderUserManagement() {

        const subWindowCustomStyle = {
            right: isUserControlOpen && isMenuOpen ? '0' : '-100%',
            overflow: 'auto',
            transition: 'right 0.5s ease-in-out',
            direction: isHebrew() ? 'rtl' : 'ltr',
        };

        return (
            <div className="sub-window" style={subWindowCustomStyle}>
                {renderAccessRequestSection()}
                <div className="vertical-space"></div>
                <div className="vertical-space"></div>
                <div className="vertical-space"></div>
                <hr/>
                {renderUserTableSection()}
                {renderEditUserSection()}
                {renderAddUserSection()}
                <div className="button-container menu">
                    {!isEditSelected && !isAddUserSectionOpen && (
                        <div className="add-user-container">
                            <AiOutlinePlus size={24}
                                           className={isAddUserButtonClicked ? "add-icon-clicked" : "add-icon"}
                                           onClick={handleAddUser}/>
                            <AiOutlineMinus size={24}
                                            className={isRemoveUserButtonClicked ? "remove-icon-clicked" : "remove-icon"}
                                            onClick={handleRemoveUser}/>
                            <MdEdit size={24} className={isEditUserButtonClicked ? "edit-icon-clicked" : "edit-icon"}
                                    onClick={handleEditUser}/>
                        </div>
                    )}
                </div>
                <div className="message-box" style={{color: textColor}}>{message}</div>
                <div className="vertical-space"></div>
                <div className="vertical-space"></div>
                <div className="vertical-space"></div>
                <button className="user-button" onClick={handleExitWindow}> {translate("Exit")}</button>
                <hr/>
                <div className="vertical-space"></div>
            </div>

        );
    }


    return (
        renderUserManagement()
    );
};

export default memo(UserControlMenu);

