import '../styles/ControlMenu.css';
import React, {memo, useState, useMemo, useCallback} from 'react';
import {slide as Menu} from "react-burger-menu";
import StockAPI from './StockApi';
import MarkingControlMenu from './MarkiingControlMenu';
import UserControlMenu from './UserControlMenu';
import {useNavigate} from 'react-router-dom';
import {translate} from "./App";
import MonitoringServerWindow from "./MonitoringServerWindow";
import {addLog} from "../redux/dataBankReducer";
import {useDispatch, useSelector} from "react-redux";


const ControlMenu = ({handleLogCode}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const backToLogin = useCallback(() => {
        navigate('/login');
    }, [navigate]);
    const stockApi = useMemo(() => new StockAPI(handleLogCode, backToLogin), [handleLogCode, backToLogin]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMarkingControlOpen, setIsMarkingControlOpen] = useState(false);
    const [isMonitoringControlOpen, setIsMonitoringControlOpen] = useState(false);
    const [isUserControlOpen, setIsUserControlOpen] = useState(false);
    const roles = useSelector(state => state.dataBank.roles);

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };


    const handleManageUsers = () => {
        if (!isUserControlOpen) {
            setIsUserControlOpen(true);
            setIsMarkingControlOpen(false);
            setIsMonitoringControlOpen(false);
        } else {
            setIsUserControlOpen(false);
        }
    };

    const handleMarkingControl = () => {
        if (!isMarkingControlOpen) {
            setIsMarkingControlOpen(true);
            setIsUserControlOpen(false);
            setIsMonitoringControlOpen(false);
        } else {
            setIsMarkingControlOpen(false);
        }
    };

    const handleMonitoringServer = () => {
        if (!isMonitoringControlOpen) {
            setIsMonitoringControlOpen(true);
            setIsMarkingControlOpen(false);
            setIsUserControlOpen(false);
        } else {
            setIsMonitoringControlOpen(false);
        }
    };

    const handleShutdownServer = () => {
        stockApi.shutDown();
    };

    const handleLogout = () => {
        stockApi.closeProgram();
        localStorage.removeItem("user-role");
        localStorage.removeItem("user-name");
        localStorage.removeItem("user-token");
        // Back to login page.
        // stockApi.handleLogCode(403);
        navigate('/login');
    };

    const handleExitSubWindow = () => {
        try {
            // Reset all control menu states.
            setIsMarkingControlOpen(false);
            setIsUserControlOpen(false);
        } catch (error) {
            dispatch(addLog([`Error exit sub window: ${error}`, 2]));
        }
        handleMenuClose();
    };

    const menuOptions = {
        'Manage Users': {
            handler: handleManageUsers,
            authorizationLevel: 1,
        },
        'Marking Control': {
            handler: handleMarkingControl,
            authorizationLevel: 2,
        },
        'Monitoring Server': {
            handler: handleMonitoringServer,
            authorizationLevel: 2,
        },
        'Shutdown Server': {
            handler: handleShutdownServer,
            authorizationLevel: 1,
        },
        'Logout': {
            handler: handleLogout,
            authorizationLevel: 100,
        },
    };

    const authorizedMenuOptions = Object.keys(menuOptions).filter(option => {
        const optionAuthorizationLevel = menuOptions[option].authorizationLevel;
        return optionAuthorizationLevel >= roles[localStorage.getItem("user-role")];
    });

    const handleMenuItemClick = (menuItem) => {
        if (menuOptions[menuItem] && menuOptions[menuItem].handler) {
            menuOptions[menuItem].handler();
        }
    };

    return (
        <>
            <Menu className='bm-burger-menu' isOpen={isMenuOpen}
                  onStateChange={({isOpen}) => {
                      setIsMenuOpen(isOpen)
                      if (!isOpen) {
                          handleExitSubWindow();
                      }
                  }}>
                {/* Authorized menu options */}
                {authorizedMenuOptions.map((option) => {
                    return (
                        <button className="menu-item" key={option} onClick={() => handleMenuItemClick(option)}>
                            {translate(option)}
                        </button>
                    );
                })}
            </Menu>
            <UserControlMenu
                isUserControlOpen={isUserControlOpen}
                isMenuOpen={isMenuOpen}
                handleExitSubWindow={handleExitSubWindow}
                handleLogCode={handleLogCode}
            />
            <MarkingControlMenu
                isMarkingControlOpen={isMarkingControlOpen}
                isMenuOpen={isMenuOpen}
                handleExitSubWindow={handleExitSubWindow}
                handleLogCode={handleLogCode}
            />
            <MonitoringServerWindow isMonitoringControlOpen={isMonitoringControlOpen}
                                    isMenuOpen={isMenuOpen}
                                    handleExitSubWindow={handleExitSubWindow}/>
        </>
    );
};


export default memo(ControlMenu);
