import '../styles/App.css';
import React, {memo, useEffect} from 'react';
import LoginPage from './LoginPage';
import DashboardComponent from './DashboardComponent';
import {HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import HomePage from './HomePage';
import NotFoundComponent from './NotFoundComponent';
import translations from "../resources/langFile.json";

function App() {

    useEffect(() => {
        if (!localStorage.getItem('appLocale')) {
            localStorage.setItem('appLocale', 'en-US')
        }
    }, []);
    const handleLogCode = (newLoggedValue) => {
        if (!newLoggedValue) {
            // If logging out, navigate to login page
            localStorage.setItem('isLoggedIn', false);
        } else {
            localStorage.setItem('isLoggedIn', true);
        }
    };

    return (
        <Router>
        <Routes>
            <Route path="" element={<Navigate to="/home"/>}/>
            <Route path="/" element={<Navigate to="/home"/>}/>
            <Route path="/home" element={<HomePage/>}/>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/dashboard" element={<ProtectedRoute/>}>
                <Route index element={<DashboardComponent handleLogCode={handleLogCode}/>}/>
            </Route>
            <Route path="*" element={<NotFoundComponent/>}/>
        </Routes>
    </Router>);
}

export default memo(App);

export const translate = (text) => {

    let currentLocale = localStorage.getItem('appLocale') || 'en-US';
    if (translations[currentLocale] && translations[currentLocale][text]) {
        return translations[currentLocale][text];
    } else {
        console.warn(`Could not find translation for: ${text}`);
        return text;
    }
};


export const isHebrew = () => {
    return localStorage.getItem('appLocale') && localStorage.getItem('appLocale') === 'he-IL';
}