import React from 'react';
import '../styles/IntervalSelector.css';
import {translate} from "./App";
import {useSelector} from "react-redux";


function IntervalSelector({index, handleIntervalChange}) {
    const interval_options = useSelector(state => state.dataBank.intervalOptions);
    const intervals = useSelector(state => state.dataBank.intervals);
    return (
        <div className="interval-selector">
            <label>{translate('Interval')}:</label>
            <select id="interval-select" value={intervals[index]} onChange={handleIntervalChange}>
                {interval_options.map((option) => {
                    return (
                        <option key={option.value} value={option.value} selected={option.selected}>
                            {translate(option.label)}
                        </option>)
                })}
            </select>
        </div>
    );
}

export default IntervalSelector;
